import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { FaEdit, FaPlus, FaTrash, FaTimes, } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import Message from '../components/Message.jsx';
import Loader from '../components/Loader.jsx';

import { useGetMyWorklistsQuery, useCreateWorklistMutation, useGetWorklistDetailsQuery, useCreateActivityMutation } from '../slices/worklistsApiSlice.js';
import { setCredentials } from '../slices/authSlice.js';


import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import '../../src/components/TimePicker.css'
import MyClinicalSCommitments from '../components/MyClinicalCommitments.jsx';
import MyProfile from '../components/MyProfile.jsx';
import { calcTotal } from '../utils/calcTotals.js'


//#f5f5dc
const dropdownStyle = {
    backgroundColor: '#F5F5F5'    //'#F0E68C'                 //'lightyellow',
    // Add other styles as needed
};
const textStyle = {
    backgroundColor: '#f5f5dc'
    // Add other styles as needed
};




const WorklistEditScreen = () => {



    const { userInfo } = useSelector((state) => state.auth);

    console.log('userInfo._id', userInfo._id);



    //const { data: worklists, isLoading, error } = useGetMyWorklistsQuery();

    const { id: worklistId } = useParams();

    // get the activities from the worklist
    const {
        data: worklist,
        refetch,
        isLoading,
        error,
    } = useGetWorklistDetailsQuery(worklistId);


    const dispatch = useDispatch();


    const [createActivity, { isLoading: loadingCreate }] =
        useCreateActivityMutation(worklistId);

    const createActivityHandler = async () => {
        if (window.confirm(`Are you sure you want to create a new activity? for ${worklistId}`)) {
            try {
                await createActivity(worklistId);
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };


    return (
        <>
            <Link to='/worklist' className='btn btn-light mb-4'>
                Go Back
            </Link>


            <Row>
                <Col md={12}>






                    <Row>
                        <Col md={4}>
                            <h2>My Activities</h2>
                        </Col>
                    </Row>

                    <Row>

                        <Col md={4}>

                            <MyProfile />
                        </Col>

                        <Col md={4}>


                        </Col>

                        <Col md={4} className='text-end'>

                            <MyClinicalSCommitments />
                        </Col>
                    </Row>





                    <Col md={12} className='text-end'>
                        <Button className='my-3' onClick={createActivityHandler}>
                            <FaPlus />  Activity
                        </Button>
                    </Col>








                    {isLoading ? (
                        <Loader />
                    ) : error ? (
                        <Message variant='danger'>
                            {error?.data?.message || error.error}
                        </Message>
                    ) : (


                        <Table striped bordered hover responsive className='table-sm'>
                            <thead>
                                <tr>
                                    <th>ClinicalSite</th>
                                    <th>Activity</th>
                                    <th>Day</th>
                                    <th>Period</th>
                                    <th>Start Time</th>

                                    <th>End Time</th>



                                    <th style={{ backgroundColor: 'lightyellow' }}>
                                        Total Activities {calcTotal(worklist.worklistItems)}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>



                                {worklist.worklistItems
                                    .slice()
                                    .sort((a, b) => {
                                        // Compare by day
                                        const dayComparison = a.day.localeCompare(b.day);

                                        if (dayComparison !== 0) {
                                            return dayComparison;
                                        }

                                        // Compare by period
                                        const periodComparison = a.period.localeCompare(b.period);

                                        if (periodComparison !== 0) {
                                            return periodComparison;
                                        }

                                        // Create Date objects for start times
                                        const startTimeA = new Date(0, 0, 0, a.startHour, a.startMin);
                                        const startTimeB = new Date(0, 0, 0, b.startHour, b.startMin);

                                        // Compare by start time
                                        const startTimeComparison = startTimeA - startTimeB;

                                        if (startTimeComparison !== 0) {
                                            return startTimeComparison;
                                        }

                                        // If start times are the same, compare by activity
                                        return a.activity.localeCompare(b.activity);
                                    })

                                    /*
                                    .sort((a, b) => {
                                        // Compare by day
                                        const dayComparison = a.day.localeCompare(b.day);

                                        if (dayComparison !== 0) {
                                            return dayComparison;
                                        }

                                        // Create Date objects for start times
                                        const startTimeA = new Date(0, 0, 0, a.startHour, a.startMin);
                                        const startTimeB = new Date(0, 0, 0, b.startHour, b.startMin);

                                        // Compare by start time
                                        const startTimeComparison = startTimeA - startTimeB;

                                        if (startTimeComparison !== 0) {
                                            return startTimeComparison;
                                        }

                                        // If start times are the same, compare by activity
                                        return a.activity.localeCompare(b.activity);
                                    })

                                    */
                                    .map((item) => (
                                        <tr key={item.UKey} className={(item.activity.startsWith('z') || item.day.startsWith('0')) ? 'highlightRow' : ''}>
                                            <td>{item.clinicalSite}</td>
                                            <td>{item.activity}</td>
                                            <td>{item.day}</td>
                                            <td>{item.period}</td>
                                            <td>{item.startHour}:{item.startMin}</td>
                                            <td>{item.endHour}:{item.endMin}</td>
                                            <td>


                                                <td>
                                                    <LinkContainer to={`/worklist/${worklist._id}/activity/${item.UKey}`}>
                                                        <Button variant='outline-info' className='btn-sm mx-2'>
                                                            <FaEdit /> Edit Item
                                                        </Button>
                                                    </LinkContainer>

                                                    <Button
                                                        variant='warning'
                                                        className='btn-sm'
                                                    //onClick={() => deleteHandler(item.UKey)}
                                                    >
                                                        <FaTrash className='text-end' style={{ color: 'white' }} /> Delete
                                                    </Button>



                                                    {/*
                                                    <Button variant='primary'>Primary</Button>
                                                    <Button variant='secondary'>Secondary</Button>
                                                    <Button variant='success'>Success</Button>
                                                    <Button variant='danger'>Danger</Button>
                                                    <Button variant='warning'>Warning</Button>
                                                    <Button variant='info'>Info</Button>
                                                    <Button variant='light'>Light</Button>
                                                    <Button variant='dark'>Dark</Button>
                                                    <Button variant='link'>Link</Button>
                                                    */}
                                                </td>


                                            </td>
                                        </tr>
                                    ))}

                            </tbody>
                        </Table>
                    )}
                </Col>
            </Row >
        </>
    );
};

export default WorklistEditScreen;
