import { useState, useEffect,  useRef  } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { FaEdit, FaPlus, FaTrash, FaTimes, FaCheck, } from 'react-icons/fa';

import {
    useGetMyPrimaryNotificationsQuery,
    useGetPrimaryNotificationsQuery,
    useGetPrimaryNotificationDetailsQuery,
    useUpdatePrimaryNotificationMutation,
}
    from '../slices/primaryNotificationApiSlice';



const PrimaryNotificationEditScreen = () => {

    const { pageNumber } = useParams();

    const updateButtonRef = useRef(null);

    const { id: primaryNotificationId } = useParams();
    const { userInfo } = useSelector((state) => state.auth);
    console.log("in PN edit seceen: primaryNotificationId ", primaryNotificationId)

    const [isManagerApproved, setIsManagerApproved] = useState();
    const [isAdminApproved, setIsAdminApproved] = useState();
    const [primaryNotificationCode, setPrimaryNotificationCode] = useState();
    const [description, setDescription] = useState();
    const [pnWte, setPnWte] = useState();
    const [activeYorN, setActiveYorN] = useState('');    
    const [groupRef, setGroupRef] = useState('');


    const {
        data: primaryNotification,
        refetch,
        isLoading,
        error,
    } = useGetPrimaryNotificationDetailsQuery(primaryNotificationId);

    //#f5f5dc
    const dropdownStyle = {
        backgroundColor: '#F5F5F5' //#F0E68C'   // '#f0f8ff'                 //'lightyellow',
        // Add other styles as needed
    };
    const textStyle = {
        backgroundColor: '#f5f5dc'
        // Add other styles as needed
    };


    //console.log(" data: primaryNotification ", primaryNotification)
    //console.log(" data: primaryNotification Code", primaryNotification.primaryNotification)


    const [updatePrimaryNotification, { isLoading: loadingUpdate }] =
        useUpdatePrimaryNotificationMutation();


    const navigate = useNavigate();

    const submitHandler = async (e) => {
    
        console.log("in submit handler : ", activeYorN)

        const details = {
            primaryNotificationId,
            primaryNotificationCode,
            description,
            isManagerApproved,
            isAdminApproved,
            pnWte,
            activeYorN,
            groupRef,
        };

        e.preventDefault();
        try {
            await updatePrimaryNotification({
                primaryNotificationId,
                details,

            }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
            toast.success('Primary Notification updated');
            refetch();
            navigate('/primaryNotification');
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    /*
    useEffect(() => {
        // Set an initial date when the component mounts
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        setReviewDate(formattedDate);
        

    }, []); // The empty dependency array ensures this effect runs only once when the component mounts

*/

    useEffect(() => {

        // This effect will run whenever the state variable activeYorN changes
        // If activeYorN has changed to "No", trigger a click event on the update button
        if (activeYorN === "No" && updateButtonRef.current) {
            updateButtonRef.current.click();
        }


        if (primaryNotification) {

            setPrimaryNotificationCode(primaryNotification.primaryNotification);
            setDescription(primaryNotification.description)
            setIsManagerApproved(primaryNotification.isManagerApproved)
            setIsAdminApproved(primaryNotification.isAdminApproved)
            setPnWte(primaryNotification.pnWte)
            setActiveYorN(primaryNotification.activeYorN)            
            setGroupRef(primaryNotification.groupRef)

        }
        // console.log(" useEffect: primaryNotification Code", primaryNotification.primaryNotification)

    }, [primaryNotification, activeYorN]);


/*
    const deleteHandler = async (id) => {
        if (window.confirm(`Are you sure  ${id}`)) {
            try {
                await deleteClinicalSCommitment(id);
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };
    */

    const deleteHandler = async (id) => {
        if (window.confirm(`Are you sure  ${id}`)) {            
                 setActiveYorN("No");                 
                
            }            

           
        };
    


    return (
        <>
            <Link to='/primaryNotification' className='btn btn-light my-3'>
                Go Back
            </Link>
            <FormContainer>
                <h1>Edit Primary Notification</h1>
                {loadingUpdate && <Loader />}

                {isLoading ? (
                    <Loader />
                ) : error ? (
                    <Message variant='danger'>{error.data.message}</Message>
                ) : (
                    <Form onSubmit={submitHandler}>


                        <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>


                            <Form.Group controlId='primaryNotification'>
                                <Form.Label>Primary Notification</Form.Label>

                                <Form.Control
                                    type='text'
                                    placeholder='Primary Notification Code'
                                    value={primaryNotificationCode}  //}{primaryNotificationCode}
                                    onChange={(e) => setPrimaryNotificationCode(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>




                            <Form.Group controlId='man_approve'>
                                <Form.Label>Manager Approve Yes or No</Form.Label>
                                <Form.Control
                                    as='select'
                                    placeholder='man_approve'
                                    value={isManagerApproved ? 'Yes' : 'No'} // Convert true/false to 'Yes' or 'No'
                                    onChange={(e) => setIsManagerApproved(e.target.value === 'Yes')} // Convert 'Yes' or 'No' to true/false
                                    style={dropdownStyle}
                                >
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='Admin_approve'>
                                <Form.Label>Admin Approve Yes or No</Form.Label>
                                <Form.Control
                                    as='select'
                                    placeholder='Admin_approve'
                                    value={isAdminApproved ? 'Yes' : 'No'} // Convert true/false to 'Yes' or 'No'
                                    onChange={(e) => setIsAdminApproved(e.target.value === 'Yes')} // Convert 'Yes' or 'No' to true/false
                                    style={dropdownStyle}
                                >
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='WTE'>
                                <Form.Label>WTE on Primary Notification</Form.Label>

                                <Form.Control
                                    type='number'
                                    placeholder='Primary Notification WTE'
                                    value={pnWte}
                                    onChange={(e) => setPnWte(e.target.value)} style={textStyle}
                                    step='0.01' // Set the step attribute to 0.01
                                ></Form.Control>
                            </Form.Group>

                            
                            <Form.Group controlId='groupRef'>
                                <Form.Label>Group Reference</Form.Label>

                                <Form.Control
                                    type='text'
                                    placeholder='Group Ref'
                                    value={groupRef}  //}{primaryNotificationCode}
                                    onChange={(e) => setGroupRef(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>

                            
                            <Form.Group controlId='Description'>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as='textarea'
                                            rows={8}
                                            placeholder='Enter your comments here...'
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            style={textStyle}
                                        />
                            </Form.Group>




                        </div>

                        <div style={{ display: 'flex' , paddingLeft: '10px'}}>
                                <Button
                                ref={updateButtonRef}
                                    type='submit'
                                    variant='primary'
                                    className='btn-sm'
                                    style={{ marginTop: '1rem', paddingRight: '10px' }}
                                >
                                <FaEdit />  Update
                                </Button>

                                
                        <div style={{  paddingLeft: '10px'}}> 
                                <Button
                                variant='warning'
                                className='btn-sm'
                                style={{  paddingLeft: '10px', marginTop: '1rem' }}
                            onClick={() => deleteHandler(primaryNotificationId )}
                            >
                                <FaTrash  style={{ color: 'white' }}  /> Delete
                            </Button>
                        </div>

                    </div>

                    </Form>
                )}
            </FormContainer>
        </>
    );
};

export default PrimaryNotificationEditScreen;
