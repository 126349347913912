import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { FaEdit, FaPlus, FaTrash, FaTimes, FaCheck, } from 'react-icons/fa';

import { toast } from 'react-toastify';
import Message from '../components/Message';
import Loader from '../components/Loader';

import { useGetMyPrimaryNotificationsQuery, useCreatePrimaryNotificationMutation } from '../slices/primaryNotificationApiSlice.js';
import { setCredentials } from '../slices/authSlice';
import { Link } from 'react-router-dom';
import MyClinicalSCommitments from '../components/MyClinicalCommitments';
import MyProfile from '../components/MyProfile';
import { calcTotal } from '../utils/calcTotals.js'


//#f5f5dc
const dropdownStyle = {
    backgroundColor: '#F5F5F5'    //'#F0E68C'                 //'lightyellow',
    // Add other styles as needed
};
const textStyle = {
    backgroundColor: '#f5f5dc'
    // Add other styles as needed
};


const PrimaryNotificationScreen = () => {




    const { userInfo } = useSelector((state) => state.auth);

    //const { pageNumber } = useParams();
    const { myUserId } = userInfo._id

    console.log('userInfo._id', userInfo._id);



    const { data: primaryNotifications, isLoading, error, refetch } = useGetMyPrimaryNotificationsQuery();

    const dispatch = useDispatch();


    const [createPrimaryNotification, { isLoading: loadingCreate }] =
        useCreatePrimaryNotificationMutation();

    const createPrimaryNotificationHandler = async () => {
        if (window.confirm('Are you sure you want to create a new Primary Notification?')) {
            try {
                await createPrimaryNotification();
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };



    return (
        <>
            <Link to='/' className='btn btn-light mb-4'>
                Go Back
            </Link>


            <Row>
                <Col md={12}>

                    <Row><Col md={4}>
                        <h2>Primary Notifications</h2>
                    </Col>

                    </Row>
                    


                    {isLoading ? (
                        <Loader />
                    ) : error ? (
                        <Message variant='danger'>
                            {error?.data?.message || error.error}
                        </Message>
                    ) : (

                        <>

                            {primaryNotifications.length >= 0 ? (
                                <Col md={12} className='text-end'>
                                    <Button className='my-3' onClick={createPrimaryNotificationHandler}>
                                        <FaPlus /> Create PrimaryNotification
                                    </Button>
                                </Col>

                            ) : (

                                <Col md={12} className='text-end'>
                                    <th> </th>
                                </Col>


                            )}




                            < Table striped bordered hover responsive className='table-sm'>
                                <thead>
                                    <tr>
                                        <th>Primary Notification ID</th>
                                        <th>Created</th>

                                        <th>Manager Approved</th>
                                        <th>HR Approved</th>
                                        <th>Primary Notification WTE</th>

                                        <th style={{ backgroundColor: 'lightyellow' }}>LineItems WTE</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {primaryNotifications.map((primaryNotification) => (
                                        <tr key={primaryNotification._id}>
                                            <td>{primaryNotification.primaryNotification}</td>
                                            <td>{primaryNotification.createdAt.substring(0, 10)}</td>


                                            <td>
                                                {primaryNotification.isManagerApproved ? (
                                                    //primaryNotification.manApprovedAt.substring(0, 10)
                                                    <FaCheck style={{ color: 'green' }} />
                                                ) : (
                                                    <FaTimes style={{ color: 'red' }} />
                                                )}
                                            </td>
                                            <td>
                                                {primaryNotification.isAdminApproved ? (
                                                    //primaryNotification.adminApprovedAt.substring(0, 10)
                                                    <FaCheck style={{ color: 'green' }} />
                                                ) : (
                                                    <FaTimes style={{ color: 'red' }} />
                                                )}
                                            </td>
                                            <td>{(Math.round(primaryNotification.pnWte * 100) / 100).toFixed(2)}</td>


                                            { +calcTotal(primaryNotification.primaryNotificationItems) <= +((Math.round(primaryNotification.pnWte * 100) / 100).toFixed(2))  ? (
                                                    //primaryNotification.manApprovedAt.substring(0, 10)
                                                    <td style={{ backgroundColor: 'lightyellow', width: '10%' }}>{+calcTotal(primaryNotification.primaryNotificationItems)}</td>
                                                ) : (
                                                    <td style={{ backgroundColor: '#F4BFBF', width: '10%' }}>{+calcTotal(primaryNotification.primaryNotificationItems)}</td>
                                                )}


                                           
                                            <td>

                                                <LinkContainer to={`/primaryNotification/${primaryNotification._id}/edit`}>
                                                    <Button variant='outline-info' className='btn-sm mx-2'>
                                                        <FaEdit /> Edit PN
                                                    </Button>
                                                </LinkContainer>

                                                <LinkContainer to={`/primaryNotification/${primaryNotification._id}:${primaryNotification.pnWte}`}>
                                                    <Button variant='outline-info' className='btn-sm mx-2'>
                                                        <td style={{ backgroundColor: 'Moccasin', width: '10%' }}> <FaEdit /> Edit LineItems</td>
                                                    </Button>
                                                </LinkContainer>

                                               


                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </Col>
            </Row >
        </>
    );
};

export default PrimaryNotificationScreen;