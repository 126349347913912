import { CLINICALSITES_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const clinicalSitesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getClinicalSites: builder.query({
            query: ({ keyword, pageNumber }) => ({
                url: CLINICALSITES_URL,
                params: { keyword, pageNumber },
            }),
            keepUnusedDataFor: 5,
            providesTags: ['ClinicalSites'],
        }),

        getClinicalSitesNP: builder.query({
            query: () => ({
                url: `${CLINICALSITES_URL}/list`,

            }),
            keepUnusedDataFor: 5,
            providesTags: ['ClinicalSites'],
        }),



        getClinicalSiteDetails: builder.query({
            query: (clinicalSiteId) => ({
                url: `${CLINICALSITES_URL}/${clinicalSiteId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        createClinicalSite: builder.mutation({
            query: () => ({
                url: `${CLINICALSITES_URL}`,
                method: 'POST',
            }),
            invalidatesTags: ['ClinicalSite'],
        }),
        updateClinicalSite: builder.mutation({
            query: (data) => ({
                url: `${CLINICALSITES_URL}/${data.clinicalSiteId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['ClinicalSite'],
        }),
        deleteClinicalSite: builder.mutation({
            query: (clinicalSiteId) => ({
                url: `${CLINICALSITES_URL}/${clinicalSiteId}`,
                method: 'DELETE',
            }),
            providesTags: ['ClinicalSite'],
        }),


    }),
});

export const {
    useGetClinicalSitesQuery,
    useGetClinicalSitesNPQuery,
    useGetClinicalSiteDetailsQuery,
    useCreateClinicalSiteMutation,
    useUpdateClinicalSiteMutation,
    useDeleteClinicalSiteMutation,
} = clinicalSitesApiSlice;
