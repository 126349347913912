import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
    useGetUserDetailsQuery,
    useUpdateUserMutation,
} from '../../slices/usersApiSlice'; // the admin
import {useGetDirectoratesNoPageQuery,} from '../../slices/directoratesApiSlice.js';
import {useGetCostcentresNoPageQuery,} from '../../slices/costcentresApiSlice.js'; 


//import { useProfileMutation, useGetUserProDetailsQuery } from '../slices/usersApiSlice'; //the user

const UserEditScreen = () => {
    const { id: userId } = useParams();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');

    const [employeenumber, setEmployeenumber] = useState('');
    const [jobtitle, setJobtitle] = useState('');
    const [speciality, setSpeciality] = useState('');
    const [location, setLocation] = useState('');
    const [directoratedepartment, setDirectoratedepartment] = useState('');   
    const [comment, setComment] = useState('');
    const [effectiveDate, setEffectiveDate] = useState(new Date());
    const [reviewDate, setReviewDate] = useState(new Date());

    const { data: directoratesdata, isLoading: isLoadingD, error: errorD, refetch: refetchD } = useGetDirectoratesNoPageQuery();
    const { data: costcentresdata, isLoading: isLoadingBC, error: errorBC, refetch: refetchBC } = useGetCostcentresNoPageQuery();


    //#f5f5dc
    const dropdownStyle = {
        backgroundColor: '#F5F5F5'    //'#F0E68C'                 //'lightyellow',
        // Add other styles as needed
    };
    const textStyle = {
        backgroundColor: '#f5f5dc'
        // Add other styles as needed
    };


    const {
        data: user,
        isLoading,
        error,
        refetch,
    } = useGetUserDetailsQuery(userId);





    const [updateUser, { isLoading: loadingUpdate }] = useUpdateUserMutation();

    const navigate = useNavigate();

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            await updateUser({
                userId, name, email, isAdmin, isApproved,
                employeenumber, firstname, lastname,
                jobtitle,
                speciality,
                location,
                directoratedepartment,              
                effectiveDate,                
                reviewDate,
                comment,
            });
            toast.success('user updated successfully');
            refetch();
            navigate('/admin/userlist');
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    useEffect(() => {


        if (user) {
            setName(user.name);
            setEmail(user.email);
            setIsAdmin(user.isAdmin);
            setIsApproved(user.isApproved);
            
            setFirstname(user.firstname);
            setLastname(user.lastname);

            setEmployeenumber(user.employeenumber);
            setJobtitle(user.jobtitle);
            setSpeciality(user.speciality);
            setLocation(user.location);
            setDirectoratedepartment(user.directoratedepartment);            
            setComment(user.comment);
            const reviewDate1 = new Date((user.reviewDate));

            let reviewDate2 = ""

            if (reviewDate1 && reviewDate1 instanceof Date && !isNaN(reviewDate1.getTime())) {
                reviewDate2 = reviewDate1.toISOString().slice(0, 10);
                // Further processing with reviewDate2
            } else {
                reviewDate2 = ""
                //console.error('reviewDate1 is not a valid Date object or is null/undefined');
                // Handle the case where reviewDate1 is not a valid date or is null/undefined
            }

            //console.log('user.reviewdate ,reviewDate1 , reviewDate2 ', user.reviewdate, " ", reviewDate1, " ", reviewDate2);

            const effectiveDate1 = new Date((user.effectiveDate));
            //const effectiveDate2 = effectiveDate1.toISOString().slice(0, 10);
            //const effectiveDate2 = (effectiveDate1 ?? '').toISOString().slice(0, 10);

            let effectiveDate2 = ""

            if (effectiveDate1 && effectiveDate1 instanceof Date && !isNaN(effectiveDate1.getTime())) {
                effectiveDate2 = effectiveDate1.toISOString().slice(0, 10);
                // Further processing with reviewDate2
            } else {
                effectiveDate2 = ""
                //console.error('reviewDate1 is not a valid Date object or is null/undefined');
                // Handle the case where reviewDate1 is not a valid date or is null/undefined
            }            

            setReviewDate(reviewDate2);
            setEffectiveDate(effectiveDate2);



        }
    }, [user]);

    return (
        <>
            <Link to='/admin/userlist' className='btn btn-light my-3'>
                Go Back
            </Link>
            <Container fluid>

                {isLoadingBC && <Loader />}
                {isLoadingD && <Loader />}
                
                {loadingUpdate && <Loader />}
                {isLoading ? (
                    <Loader />
                ) : error ? (
                    <Message variant='danger'>
                        {error?.data?.message || error.error}
                    </Message>
                ) : (
                    <Form onSubmit={submitHandler}>
                        <h1>Edit User Profile </h1> <h5>{user.name}</h5>
                        <Row>
                            {/* First Column */}
                            <Col md={4}>
                                <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>


                                    { /*<Form.Group className='my-2' controlId='name'>************************************************************************
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type='name'
                                        placeholder='Enter name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    </Form.Group> */ }
                                    {/* Add other fields for the first column */}

                                    <Form.Group className='my-2' controlId='employeenumber'>
                                        <Form.Label>Employee Number</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Enter Employee Number'
                                            value={employeenumber}
                                            onChange={(e) => setEmployeenumber(e.target.value)}
                                            style={textStyle}
                                        />
                                    </Form.Group>

                                    <Form.Group className='my-2' controlId='firstname'>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='firstname'
                                            value={firstname}
                                            onChange={(e) => setFirstname(e.target.value)}
                                            style={textStyle}
                                        />
                                    </Form.Group>

                                    <Form.Group className='my-2' controlId='lastname'>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type='lastname'
                                            placeholder='lastname'
                                            value={lastname}
                                            onChange={(e) => setLastname(e.target.value)}
                                            style={textStyle}
                                        />
                                    </Form.Group>

                                    <Form.Group className='my-2' controlId='email'>
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control
                                            type='email'
                                            placeholder='Email Address'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            style={textStyle}
                                        />
                                    </Form.Group>


                                    <Form.Group className='my-2' controlId='jobtitle'>
                                        <Form.Label>Job Title</Form.Label>
                                        <Form.Control
                                            type='jobtitle'
                                            placeholder='Enter Job Title'
                                            value={jobtitle}
                                            onChange={(e) => setJobtitle(e.target.value)}
                                            style={textStyle}
                                        />
                                    </Form.Group>
                                    <Form.Group className='my-2' controlId='effectiveDate'>
                                        <Form.Label>Effective Date</Form.Label>
                                        <Form.Control
                                            type='date'
                                            placeholder='Effective Date'
                                            value={effectiveDate}
                                            onChange={(e) => setEffectiveDate(e.target.value)}
                                            style={textStyle}
                                        />
                                    </Form.Group>

                                   

                                </div>






                            </Col>




                            {/* Second Column **************************************************************************************/}
                            <Col md={4}>
                                <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>





                                <Form.Group controlId='setDirectorate'>
                                            <Form.Label>Directorate</Form.Label>
                                            <Form.Control

                                                as='select'
                                                placeholder='Directorate'
                                                value={directoratedepartment}

                                                //onChange={(e) => setActivityName(e.target.value)} style={dropdownStyle} 
                                                onChange={(e) => setDirectoratedepartment(e.target.value)} 
                                                style={textStyle}
                                            >


                                                <option value={directoratedepartment}>{directoratedepartment}</option>

                                                {directoratesdata &&
                                                    directoratesdata
                                                        .slice() // Create a shallow copy of the array to avoid mutating the original array
                                                        .sort((a, b) => a.directorate.localeCompare(b.directorate)) // Sort by activityName
                                                        .map((directorateList) => (
                                                            <option key={directorateList._id} value={`${directorateList.directorate}`}>
                                                                {directorateList.directorate}
                                                            </option>
                                                        ))}




                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId='CostCentre'>
                                            <Form.Label>Cost Centre</Form.Label>
                                            <Form.Control

                                                as='select'
                                                placeholder='CostCentre'
                                                value={speciality}

                                                //onChange={(e) => setActivityName(e.target.value)} style={dropdownStyle} 
                                                onChange={(e) => setSpeciality(e.target.value)} 

                                               // style={{ width: '200px', flexBasis: 'calc(90% - 5px)', marginBottom: '10px' }}
                                               style={textStyle}

                                            >


                                                <option value={speciality}>{speciality}</option>

                                                {costcentresdata &&
                                                    costcentresdata
                                                        .slice() // Create a shallow copy of the array to avoid mutating the original array
                                                        .sort((a, b) => a.ccname.localeCompare(b.ccname)) // Sort by activityName
                                                        .map((costcentreList) => (
                                                            <option key={costcentreList._id} value={`${costcentreList.ccname}`}>
                                                                {costcentreList.ccname}
                                                            </option>
                                                        ))}




                                            </Form.Control>
                                        </Form.Group>


                                    <Form.Group className='my-2' controlId='location'>
                                        <Form.Label>Location</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Enter Location'
                                            value={location}
                                            onChange={(e) => setLocation(e.target.value)}
                                            style={textStyle}
                                        />
                                    </Form.Group>


                                   

                                    {/* Add other fields for the second column */}

                                    {/* Add other fields for the first column */}

                                    <Form.Group className='my-2' controlId='comment'>
                                        <Form.Label>Comments</Form.Label>
                                        <Form.Control
                                            as='textarea'  // Set the 'as' prop to 'textarea' for multiline text entry
                                            rows={3}       // Specify the number of visible text rows

                                            placeholder='Comments'
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                            style={textStyle}
                                        />
                                    </Form.Group>

                                </div>
                            </Col>

                            {/* thirdColumn **************************************************************************************/}
                            <Col md={4}>

                                <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>

                                                                  

                                   

                                    <Form.Group className='my-2' controlId='isApproved'>
                                    <Form.Label>Approve to allow login  </Form.Label>
                                        <Form.Check
                                            type='checkbox'
                                            label='is Approved'
                                            checked={isApproved}
                                            onChange={(e) => setIsApproved(e.target.checked)}
                                            style={textStyle}

                                        ></Form.Check>
                                    </Form.Group>

                                    <Form.Group controlId='reviewDate'>
                                        <Form.Label>Review Date  </Form.Label>
                                        <Form.Control
                                            type='date'
                                            placeholder="Review Date "
                                            value={reviewDate}   // {effectiveDate.toISOString().split('T')[0]}  //{effectiveDate}
                                            onChange={(e) => setReviewDate(e.target.value)}
                                            style={textStyle}
                                        />


                                    </Form.Group>

                                    <Form.Group className='my-2' controlId='isadmin'>
                                    <Form.Label>Give Admin rights  </Form.Label>
                                        <Form.Check
                                            type='checkbox'
                                            label='Is Admin'
                                            checked={isAdmin}
                                            onChange={(e) => setIsAdmin(e.target.checked)}
                                            style={textStyle}

                                        ></Form.Check>
                                    </Form.Group>


                                    

                                </div>

                            </Col>



                        </Row>



                        <Button type='submit' variant='primary'>
                            Update
                        </Button>
                    </Form>
                )}
            </Container>
        </>
    );
};

export default UserEditScreen;