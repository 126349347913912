import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';

import { useRegisterMutation } from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';
import {useGetDirectoratesNoPageQuery,} from '../slices/directoratesApiSlice.js';
import {useGetCostcentresNoPageQuery,} from '../slices/costcentresApiSlice.js';
import Message from '../components/Message.jsx';


//#f5f5dc
const dropdownStyle = {
  backgroundColor: '#F5F5F5'    //'#F0E68C'                 //'lightyellow',
  // Add other styles as needed
};
const textStyle = {
  backgroundColor: '#f5f5dc'
  // Add other styles as needed
};





const RegisterScreen = () => {
  const [name, setName] = useState('None');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');

  const [employeenumber, setEmployeenumber] = useState('');
  const [jobtitle, setJobtitle] = useState('');
  const [speciality, setSpeciality] = useState('');
  const [location, setLocation] = useState('');
  const [directoratedepartment, setDirectoratedepartment] = useState('');
  
  const [comment, setComment] = useState('');
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [register, { isLoading }] = useRegisterMutation();
  const { data: directoratesdata, isLoading: isLoadingD, error: errorD, refetch: refetchD } = useGetDirectoratesNoPageQuery();
  const { data: costcentresdata, isLoading: isLoadingBC, error: errorBC, refetch: refetchBC } = useGetCostcentresNoPageQuery();


  const { userInfo } = useSelector((state) => state.auth);

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/';

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
    } else {
      try {
        setName(firstname)
       // Shortened code using logical OR (||) operator
        // Shortened code using ternary operator
       // setPrimaryemployer ( (primaryemployer === true) ? primaryemployer : false);
        //setApprovedtrainer ( (approvedtrainer === true) ? approvedtrainer : false);
        //setAreyouaclinicaldirector ((areyouaclinicaldirector === true) ? areyouaclinicaldirector : false);
        //setOncallcommitmentYN ( (oncallcommitmentYN === true) ? oncallcommitmentYN : false);
        

        //console.log("oncallcommitmentcomment ", oncallcommitmentcomment)
        // Check if oncallcommitmentcomment is empty or null
        //const updatedOncallcommitmentcomment = oncallcommitmentcomment ? oncallcommitmentcomment : "None";

        const updatedName = name ? name : "None";
        const updatedComment = comment ? comment : "None";

        //setOncallcommitmentcomment(updatedOncallcommitmentcomment );

        setName(updatedName);
        setComment(updatedComment );

        //console.log("oncallcommitmentcomment 2 ", oncallcommitmentcomment)
        /* primaryemployer,
          yourclinicaldirector,
          contracttype,
          approvedtrainer,
          areyouaclinicaldirector,
          oncallcommitmentYN,
          oncallcommitmentcomment, buckleycontract,
          reviewDate,*/ 

        const res = await register({
          name, email, password, employeenumber, firstname, lastname,
          jobtitle,
          speciality,
          location,
          directoratedepartment,         
          effectiveDate,         
          comment,
        }).unwrap();
        dispatch(setCredentials({ ...res }));
        navigate(redirect);
      } catch (err) {
        toast.error(err?.data?.message + "    TRY UPDATE AGAIN " || err.error);
      }
    }
  };

  return (
    <Container fluid>
      <h1>Register</h1>
      
      {isLoadingBC && <Loader />}
      {isLoadingD ? (
                        <Loader />
                    ) : errorD ? (
                        <Message variant='danger'>{errorD.data.message}</Message>
                    ) : (


      <Form onSubmit={submitHandler}>
        <Row>
          <Col md={4}>
            <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>



              <Form.Group className='my-2' controlId='email'>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>


              <Form.Group className='my-2' controlId='employeenumber'>
                <Form.Label>Employee Number</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Employee Number'
                  value={employeenumber}
                  onChange={(e) => setEmployeenumber(e.target.value)}
                  style={textStyle}
                />
              </Form.Group>

              <Form.Group className='my-2' controlId='firstname'>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='firstname'
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  style={textStyle}
                />
              </Form.Group>

              <Form.Group className='my-2' controlId='lastname'>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type='lastname'
                  placeholder='lastname'
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  style={textStyle}
                />
              </Form.Group>

              <Form.Group className='my-2' controlId='jobtitle'>
                <Form.Label>Job Title</Form.Label>
                <Form.Control
                  type='jobtitle'
                  placeholder='Enter Job Title'
                  value={jobtitle}
                  onChange={(e) => setJobtitle(e.target.value)}
                  style={textStyle}
                />
              </Form.Group>
              <Form.Group className='my-2' controlId='effectiveDate'>
                <Form.Label>Effective Date</Form.Label>
                <Form.Control
                  type='date'
                  placeholder='Effective Date'
                  value={effectiveDate}
                  onChange={(e) => setEffectiveDate(e.target.value)}
                  style={textStyle}
                />
              </Form.Group>

             
            </div>
          </Col>

          {/* Second Column **************************************************************************************/}
          <Col md={4}>
            <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>
             

              <Form.Group controlId='setDirectorate'>
                                            <Form.Label>Directorate</Form.Label>
                                            <Form.Control

                                                as='select'
                                                placeholder='Directorate'
                                                value={directoratedepartment}

                                                //onChange={(e) => setActivityName(e.target.value)} style={dropdownStyle} 
                                                onChange={(e) => setDirectoratedepartment(e.target.value)} 
                                                style={textStyle}
                                            >


                                                <option value={directoratedepartment}>{directoratedepartment}</option>

                                                {directoratesdata &&
                                                    directoratesdata
                                                        .slice() // Create a shallow copy of the array to avoid mutating the original array
                                                        .sort((a, b) => a.directorate.localeCompare(b.directorate)) // Sort by activityName
                                                        .map((directorateList) => (
                                                            <option key={directorateList._id} value={`${directorateList.directorate}`}>
                                                                {directorateList.directorate}
                                                            </option>
                                                        ))}




                                            </Form.Control>
                                        </Form.Group>

             

              <Form.Group controlId='CostCentre'>
                                            <Form.Label>Cost Centre</Form.Label>
                                            <Form.Control

                                                as='select'
                                                placeholder='CostCentre'
                                                value={speciality}

                                                //onChange={(e) => setActivityName(e.target.value)} style={dropdownStyle} 
                                                onChange={(e) => setSpeciality(e.target.value)} 

                                               // style={{ width: '200px', flexBasis: 'calc(90% - 5px)', marginBottom: '10px' }}
                                               style={textStyle}

                                            >


                                                <option value={speciality}>{speciality}</option>

                                                {costcentresdata &&
                                                    costcentresdata
                                                        .slice() // Create a shallow copy of the array to avoid mutating the original array
                                                        .sort((a, b) => a.ccname.localeCompare(b.ccname)) // Sort by activityName
                                                        .map((costcentreList) => (
                                                            <option key={costcentreList._id} value={`${costcentreList.ccname}`}>
                                                                {costcentreList.ccname}
                                                            </option>
                                                        ))}




                                            </Form.Control>
                                        </Form.Group>


              <Form.Group className='my-2' controlId='location'>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Location'
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  style={textStyle}
                />
              </Form.Group>


             
              {/* Add other fields for the second column */}

              {/* Add other fields for the first column */}

              <Form.Group className='my-2' controlId='comment'>
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  as='textarea'  // Set the 'as' prop to 'textarea' for multiline text entry
                  rows={3}       // Specify the number of visible text rows

                  placeholder='Comments'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  style={textStyle}
                />
              </Form.Group>

            </div>
          </Col>

          {/* Third Column **************************************************************************************/}
          <Col md={4}>
            
            <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>


              <Form.Group className='my-2' controlId='password'>
                <Form.Label>Change Password, (comprised of, Caps, lowercase, numbers, and !@#$%^&* and 12 characters long) </Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Set password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>

              <Form.Group className='my-2' controlId='confirmPassword'>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Confirm password'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>

            </div>
          </Col>


        </Row>




        <Button disabled={isLoading} type='submit' variant='primary'>
          Register
        </Button>

        {isLoading && <Loader />}
      </Form>
      
      )}
      

      <Row className='py-3'>
        <Col>
          Already have an account?{' '}
          <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
            Login
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterScreen;
