import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FaTimes } from 'react-icons/fa';

import { toast } from 'react-toastify';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { useProfileMutation, useGetUserProDetailsQuery } from '../slices/usersApiSlice';
import { useGetMyOrdersQuery } from '../slices/ordersApiSlice';
import { setCredentials } from '../slices/authSlice';
import {useGetDirectoratesNoPageQuery,} from '../slices/directoratesApiSlice.js';
import {useGetCostcentresNoPageQuery,} from '../slices/costcentresApiSlice.js';


//#f5f5dc
const dropdownStyle = {
  backgroundColor: '#F5F5F5'    //'#F0E68C'                 //'lightyellow',
  // Add other styles as needed
};
const textStyle = {
  backgroundColor: '#f5f5dc'
  // Add other styles as needed
};


const ProfileScreen = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');

  const [employeenumber, setEmployeenumber] = useState('');
  const [jobtitle, setJobtitle] = useState('');
  const [speciality, setSpeciality] = useState('');
  const [location, setLocation] = useState('');
  const [directoratedepartment, setDirectoratedepartment] = useState('');
  
  const [comment, setComment] = useState('');
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  

  const { data: directoratesdata, isLoading: isLoadingD, error: errorD, refetch: refetchD } = useGetDirectoratesNoPageQuery();
  const { data: costcentresdata, isLoading: isLoadingBC, error: errorBC, refetch: refetchBC } = useGetCostcentresNoPageQuery();


  const { userInfo } = useSelector((state) => state.auth);

  console.log('userInfo._id', userInfo._id);


  const {
    data: user,
    isLoading: isLoadingU,
    error: errorU,
    refetch,
  } = useGetUserProDetailsQuery(userInfo._Id);



  const { data: orders, isLoading, error } = useGetMyOrdersQuery();

  const [updateProfile, { isLoading: loadingUpdateProfile }] =
    useProfileMutation();

  useEffect(() => {


    if (user) {
      setName(user.name);
      setEmail(user.email);
      
      setFirstname(user.firstname);
      setLastname(user.lastname);

      setEmployeenumber(user.employeenumber);
      setJobtitle(user.jobtitle);
      setSpeciality(user.speciality);
      setLocation(user.location);
      setDirectoratedepartment(user.directoratedepartment);
     
      setComment(user.comment);


      const effectiveDate1 = new Date((user.effectiveDate));
      
      let effectiveDate2 = ""

      if (effectiveDate1 && effectiveDate1 instanceof Date && !isNaN(effectiveDate1.getTime())) {
        effectiveDate2 = effectiveDate1.toISOString().slice(0, 10);
        // Further processing with reviewDate2
      } else {
        effectiveDate2 = ""
        //console.error('reviewDate1 is not a valid Date object or is null/undefined');
        // Handle the case where reviewDate1 is not a valid date or is null/undefined
      }


      setEffectiveDate(effectiveDate2);


    }
  }, [user]);

  const dispatch = useDispatch();
  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
    } else {
      try {
        const res = await updateProfile({
          _id: userInfo._id,
          name,
          email,
          password,
          employeenumber, firstname, lastname,
          jobtitle,
          speciality,
          location,
          directoratedepartment,         
          effectiveDate, 
          comment,
        }).unwrap();
        dispatch(setCredentials({ ...res }));
        toast.success('Profile updated successfully');
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (

    <Row>
      <Col md={12}>
        <h2>My Profile</h2>


        {isLoadingD && <Loader />}
        {isLoadingBC && <Loader />}
        {isLoadingU && <Loader />}
        {isLoadingU ? (
          <Loader />
        ) : errorU ? (
          <Message variant='danger'>
            {errorU?.data?.message || errorU.error}
          </Message>
        ) : (

         
          <Form onSubmit={submitHandler}>
            <Row>
              <Col md={4}>
                <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>



                  <Form.Group className='my-2' controlId='email'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={textStyle}
                    ></Form.Control>
                  </Form.Group>


                  <Form.Group className='my-2' controlId='employeenumber'>
                    <Form.Label>Employee Number</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Employee Number'
                      value={employeenumber}
                      onChange={(e) => setEmployeenumber(e.target.value)}
                      style={textStyle}
                    />
                  </Form.Group>

                  <Form.Group className='my-2' controlId='firstname'>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='firstname'
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      style={textStyle}
                    />
                  </Form.Group>

                  <Form.Group className='my-2' controlId='lastname'>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type='lastname'
                      placeholder='lastname'
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      style={textStyle}
                    />
                  </Form.Group>

                  <Form.Group className='my-2' controlId='jobtitle'>
                    <Form.Label>Job Title</Form.Label>
                    <Form.Control
                      type='jobtitle'
                      placeholder='Enter Job Title'
                      value={jobtitle}
                      onChange={(e) => setJobtitle(e.target.value)}
                      style={textStyle}
                    />
                  </Form.Group>
                  <Form.Group className='my-2' controlId='effectiveDate'>
                    <Form.Label>Effective Date</Form.Label>
                    <Form.Control
                      type='date'
                      placeholder='Effective Date'
                      value={effectiveDate}
                      onChange={(e) => setEffectiveDate(e.target.value)}
                      style={textStyle}
                    />
                  </Form.Group>

                 
                </div>
              </Col>

              {/* Second Column **************************************************************************************/}
              <Col md={4}>
                <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>





                <Form.Group controlId='setDirectorate'>
                                            <Form.Label>Directorate</Form.Label>
                                            <Form.Control

                                                as='select'
                                                placeholder='Directorate'
                                                value={directoratedepartment}

                                                //onChange={(e) => setActivityName(e.target.value)} style={dropdownStyle} 
                                                onChange={(e) => setDirectoratedepartment(e.target.value)} 
                                                style={textStyle}
                                            >


                                                <option value={directoratedepartment}>{directoratedepartment}</option>

                                                {directoratesdata &&
                                                    directoratesdata
                                                        .slice() // Create a shallow copy of the array to avoid mutating the original array
                                                        .sort((a, b) => a.directorate.localeCompare(b.directorate)) // Sort by activityName
                                                        .map((directorateList) => (
                                                            <option key={directorateList._id} value={`${directorateList.directorate}`}>
                                                                {directorateList.directorate}
                                                            </option>
                                                        ))}




                                            </Form.Control>
                                        </Form.Group>


                                        <Form.Group controlId='CostCentre'>
                                            <Form.Label>Cost Centre</Form.Label>
                                            <Form.Control

                                                as='select'
                                                placeholder='CostCentre'
                                                value={speciality}

                                                //onChange={(e) => setActivityName(e.target.value)} style={dropdownStyle} 
                                                onChange={(e) => setSpeciality(e.target.value)} 

                                               // style={{ width: '200px', flexBasis: 'calc(90% - 5px)', marginBottom: '10px' }}
                                               style={textStyle}

                                            >


                                                <option value={speciality}>{speciality}</option>

                                                {costcentresdata &&
                                                    costcentresdata
                                                        .slice() // Create a shallow copy of the array to avoid mutating the original array
                                                        .sort((a, b) => a.ccname.localeCompare(b.ccname)) // Sort by activityName
                                                        .map((costcentreList) => (
                                                            <option key={costcentreList._id} value={`${costcentreList.ccname}`}>
                                                                {costcentreList.ccname}
                                                            </option>
                                                        ))}




                                            </Form.Control>
                                        </Form.Group>


                  <Form.Group className='my-2' controlId='location'>
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Location'
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      style={textStyle}
                    />
                  </Form.Group>


                  


                  {/* Add other fields for the second column */}

                  {/* Add other fields for the first column */}

                  <Form.Group className='my-2' controlId='comment'>
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      as='textarea'  // Set the 'as' prop to 'textarea' for multiline text entry
                      rows={3}       // Specify the number of visible text rows

                      placeholder='Comments'
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      style={textStyle}
                    />
                  </Form.Group>

                </div>
              </Col>

              {/* Third Column **************************************************************************************/}
              <Col md={4}>
                
                
                <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>


                  <Form.Group className='my-2' controlId='password'>
                    <Form.Label>Change Password,  (comprised of, Caps, lowercase, numbers, and !@#$%^&* and 12 characters long)</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='Enter password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={textStyle}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className='my-2' controlId='confirmPassword'>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='Confirm password'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      style={textStyle}
                    ></Form.Control>
                  </Form.Group>

                </div>
              </Col>


            </Row>


            <Button type='submit' variant='primary'>
              Update
            </Button>
            {loadingUpdateProfile && <Loader />}
          </Form>
        )}
      </Col>


    </Row>
  );
};

export default ProfileScreen;
