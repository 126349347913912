import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import Message from './Message';
import Loader from './Loader';
import Paginate from './PaginateCommitments';
import {
    useGetMyClinicalSCommitmentsQuery,
    useDeleteClinicalSCommitmentMutation,
    useCreateClinicalSCommitmentMutation,
} from '../slices/clinicalSCommitmentsApiSlice';
import { toast } from 'react-toastify';



const MyClinicalSCommitments = () => {
    const { pageNumber } = useParams();

    const { data, isLoading, error, refetch } = useGetMyClinicalSCommitmentsQuery({
        pageNumber,
    });

    const [deleteClinicalSCommitment, { isLoading: loadingDelete }] =
        useDeleteClinicalSCommitmentMutation();

    const deleteHandler = async (id) => {
        if (window.confirm(`Are you sure  ${id}`)) {
            try {
                await deleteClinicalSCommitment(id);
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    const [createClinicalSCommitment, { isLoading: loadingCreate }] =
        useCreateClinicalSCommitmentMutation();

    const createClinicalSCommitmentHandler = async () => {
        if (window.confirm('Are you sure you want to create a new Clinical Commitment?')) {
            try {
                await createClinicalSCommitment();
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };




    return (
        <>

            <Row className='align-items-center'>
                <Col>
                    <h8>Clinical Commitments</h8>
                </Col>

            </Row>

            {loadingCreate && <Loader />}
            {loadingDelete && <Loader />}
            {isLoading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error.data.message}</Message>
            ) : (
                <>
                    <Table striped bordered hover responsive className='table-sm' style={{ fontSize: '0.8rem', padding: '0.1rem' }}>
                        <thead>
                            <tr>
                                <th style={{ width: '10%' }}>Clinical Site Name</th>
                                <th style={{ width: '10%' }}>Consultant Name</th>
                                <th style={{ backgroundColor: 'lightyellow', width: '10%' }}>Post Hours</th>

                            </tr>
                        </thead>
                        <tbody>
                            {data.clinicalSCommitments.map((clinicalSCommitment) => (
                                // Adjust 'en-GB' based on your locale clinicalSCommitment.reviewDate

                                <tr key={clinicalSCommitment._id}>

                                    <td style={{ width: '10%' }}>{clinicalSCommitment.clinicalSiteName}</td>

                                    <td style={{ width: '10%' }}>{clinicalSCommitment.consultantName}</td>
                                    <td style={{ backgroundColor: 'lightyellow', width: '10%' }}>{clinicalSCommitment.postHours}</td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Paginate pages={data.pages} page={data.page} isAdmin={true} />
                </>
            )}
        </>
    );
};

export default MyClinicalSCommitments;
