import { RADIATION_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const radiationSafetyApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRadiationSafety: builder.query({
            query: ({ keyword, pageNumber }) => ({
                url: RADIATION_URL,
                params: { keyword, pageNumber },
            }),
            keepUnusedDataFor: 5,
            providesTags: ['RadiationSafety'],
        }),


        getMyRadiationSafety: builder.query({
            query: ({ myUserId }) => ({
                url: `${RADIATION_URL}/mine`,
                params: { myUserId },
            }),
            keepUnusedDataFor: 5,

        }),




        getRadiationSafetyDetails: builder.query({
            query: (radiationSafetyId) => ({
                url: `${RADIATION_URL}/${radiationSafetyId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        createRadiationSafety: builder.mutation({
            query: () => ({
                url: `${RADIATION_URL}`,
                method: 'POST',
            }),
            invalidatesTags: ['RadiationSafety'],
        }),
        updateRadiationSafety: builder.mutation({
            query: (data) => ({
                url: `${RADIATION_URL}/${data.radiationSafetyId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['RadiationSafety'],
        }),
        deleteRadiationSafety: builder.mutation({
            query: (radiationSafetyId) => ({
                url: `${RADIATION_URL}/${radiationSafetyId}`,
                method: 'DELETE',
            }),
            providesTags: ['RadiationSafety'],
        }),


    }),
});

export const {
    useGetRadiationSafetyQuery,
    useGetMyRadiationSafetyQuery,
    useGetRadiationSafetyDetailsQuery,
    useCreateRadiationSafetyMutation,
    useUpdateRadiationSafetyMutation,
    useDeleteRadiationSafetyMutation,
} = radiationSafetyApiSlice;
