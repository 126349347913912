import { DIRECTORATES_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const directoratesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDirectorates: builder.query({
            query: ({ keyword, pageNumber }) => ({
                url: DIRECTORATES_URL,
                params: { keyword, pageNumber },
            }),
            keepUnusedDataFor: 5,
            providesTags: ['TheDirectorates'],
        }),

        getDirectoratesNoPage: builder.query({
            query: () => ({
                url: `${DIRECTORATES_URL}/list`,

            }),
            keepUnusedDataFor: 5,
            providesTags: ['TheDirectorates'],
        }),




        getGradeDetails: builder.query({
            query: (directorateId) => ({
                url: `${DIRECTORATES_URL}/${directorateId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        createGrade: builder.mutation({
            query: () => ({
                url: `${DIRECTORATES_URL}`,
                method: 'POST',
            }),
            invalidatesTags: ['TheDirectorates'],
        }),
        updateGrade: builder.mutation({
            query: (data) => ({
                url: `${DIRECTORATES_URL}/${data.directorateId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['TheDirectorates'],
        }),
        deleteGrade: builder.mutation({
            query: (directorateId) => ({
                url: `${DIRECTORATES_URL}/${directorateId}`,
                method: 'DELETE',
            }),
            providesTags: ['TheDirectorates'],
        }),


    }),
});

export const {
    useGetDirectoratesQuery,
    useGetDirectoratesNoPageQuery,
    useGetGradeDetailsQuery,
    useCreateGradeMutation,
    useUpdateGradeMutation,
    useDeleteGradeMutation,

} = directoratesApiSlice;
