import { apiSlice } from './apiSlice';
import { PRIMARYNOTIFICATION_URL } from '../constants';


export const primaryNotificationApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createPrimaryNotification: builder.mutation({
            query: () => ({
                url: `${PRIMARYNOTIFICATION_URL}`,
                method: 'POST',

            }),
        }),

        getPrimaryNotificationDetails: builder.query({
            query: (id) => ({
                url: `${PRIMARYNOTIFICATION_URL}/${id}`,
            }),
            keepUnusedDataFor: 5,
        }),

        createPNActivity: builder.mutation({

            query: (primaryNotificationId) => ({


                url: `${PRIMARYNOTIFICATION_URL}/primaryNotification/${primaryNotificationId}/activity`,
                method: 'PUT',

            }),
        }),


        getMyPrimaryNotifications: builder.query({
            query: () => ({
                url: `${PRIMARYNOTIFICATION_URL}/mine`,
            }),
            keepUnusedDataFor: 5,
        }),

        getPrimaryNotifications: builder.query({
            query: () => ({
                url: PRIMARYNOTIFICATION_URL,
            }),
            keepUnusedDataFor: 5,
        }),

        manApprovePrimaryNotification: builder.mutation({
            query: ({ primaryNotificationId, details }) => ({
                url: `${PRIMARYNOTIFICATION_URL}/${primaryNotificationId}/pay`,
                method: 'PUT',
                body: details,
            }),
        }),

        adminApprovePrimaryNotification: builder.mutation({
            query: (primaryNotificationId) => ({
                url: `${PRIMARYNOTIFICATION_URL}/${primaryNotificationId}/deliver`,
                method: 'PUT',
            }),

        }),


        updatePrimaryNotificationActivity: builder.mutation({
            query: ({ primaryNotificationId, details, UKey }) => ({
                url: `${PRIMARYNOTIFICATION_URL}/primaryNotification/${primaryNotificationId}/activity/${UKey}`,
                method: 'PUT',
                body: details,
            }),

        }),

        updatePrimaryNotification: builder.mutation({
            query: ({ primaryNotificationId, details }) => ({
                url: `${PRIMARYNOTIFICATION_URL}/primaryNotification/${primaryNotificationId}`,
                method: 'PUT',
                body: details,
            }),

        }),

        getPrimaryNotificationItems: builder.query({
            query: ({ primaryNotificationID, activityID }) => (

                {


                    url: `${PRIMARYNOTIFICATION_URL}/primaryNotification/${primaryNotificationID}/activity/${activityID}`,
                }),

            keepUnusedDataFor: 5,
        }),



    }),
});

export const {
    useCreatePrimaryNotificationMutation,
    useCreatePNActivityMutation,
    useGetPrimaryNotificationDetailsQuery,
    useGetMyPrimaryNotificationsQuery,
    useGetPrimaryNotificationsQuery,
    useManApprovePrimaryNotificationMutation,
    useAdminApprovePrimaryNotificationMutation,
    useUpdatePrimaryNotificationActivityMutation,
    useUpdatePrimaryNotificationMutation,
    useGetPrimaryNotificationItemsQuery,

} = primaryNotificationApiSlice;
