import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  const currentYear = new Date().getFullYear();
//(<p>Primary Notifications &copy; {currentYear}</p>)

  return (
    <footer>
      <Container>
        <Row>
          <Col className='text-center py-3'>
            {1 === 1 ? (<p>Primary Notifications {currentYear}</p>) :
              (<p>The Portal &copy; {currentYear}</p>)


            }
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
