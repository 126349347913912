import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
//import { useParams } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Paginate from '../components/PaginateCommitments';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


import {

    useGetMyRadiationSafetyQuery,
    useDeleteRadiationSafetyMutation,
    useCreateRadiationSafetyMutation,
} from '../slices/radiationSafetyApiSlice';
import { toast } from 'react-toastify';

const RadiationSafetyListScreen = () => {
    //const { pageNumber } = useParams();
    const { userInfo } = useSelector((state) => state.auth);
    const { myUserId } = userInfo._id

    console.log('userInfo._id', userInfo._id);



    const { data, isLoading, error, refetch } = useGetMyRadiationSafetyQuery({ myUserId });

    const [deleteRadiationSafety, { isLoading: loadingDelete }] =
        useDeleteRadiationSafetyMutation();

    const deleteHandler = async (id) => {
        if (window.confirm(`Are you sure  ${id}`)) {
            try {
                await deleteRadiationSafety(id);
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    const [createRadiationSafety, { isLoading: loadingCreate }] =
        useCreateRadiationSafetyMutation();

    const createRadiationSafetyHandler = async () => {
        if (window.confirm('Are you sure you want to create a new Radiation Safety Record?')) {
            try {
                await createRadiationSafety();
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    // className='table-sm'th {




    return (
        <>

            <Link to='/' className='btn btn-light mb-4'>
                Go Back
            </Link>

            <Row className='align-items-center'>
                <Col>
                    <h1>Radiation Safety Records</h1>
                </Col>
                <Col className='text-end'>
                    <Button className='my-3' onClick={createRadiationSafetyHandler}>
                        <FaPlus /> Create Radiation Safety Records
                    </Button>
                </Col>
            </Row>

            {loadingCreate && <Loader />}
            {loadingDelete && <Loader />}
            {isLoading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error.data.message}</Message>
            ) : (
                <>

                    <div className='table-responsive{-sm|-md|-lg|-xl|-xxl}'>
                        <Table striped bordered hover responsive className='table-sm' position='sticky' top='0'>
                            <thead>
                                <tr>

                                    <th>Clinical Site Name</th>
                                    <th>Consultant Name</th>

                                    <th>category</th>
                                    <th>WholeBody</th>
                                    <th>Skin</th>
                                    <th>Lens Eyes</th>
                                    <th>Neutron</th>
                                    <th>Committed Effective Dose</th>
                                    <th>Radionuclide</th>
                                    <th>Activity Of Radionuclide</th>
                                    <th>Total Effective Dose mSv</th>

                                    <th>Effective Date</th>
                                    <th>Review Date</th>
                                    <th> Comments</th>
                                    <th>Active Y or N</th>

                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.radiationSafety.map((radiationSafety) => (
                                    // Adjust 'en-GB' based on your locale clinicalSCommitment.reviewDate

                                    <tr key={radiationSafety._id}>

                                        <td>{radiationSafety.clinicalSiteName}</td>


                                        <td>{radiationSafety.consultantName}</td>

                                        <td>{radiationSafety.category}</td>
                                        <td>{radiationSafety.wholeBody}</td>
                                        <td>{radiationSafety.skin}</td>
                                        <td>{radiationSafety.lensEyes}</td>
                                        <td>{radiationSafety.neutron}</td>
                                        <td>{radiationSafety.committedEffectiveDose}</td>
                                        <td>{radiationSafety.radionuclide}</td>
                                        <td>{radiationSafety.activityOfRadionuclide}</td>
                                        <td>{radiationSafety.totalEffectiveDose}</td>

                                        <td>{isNaN(Date.parse(radiationSafety.effectiveDate)) ? '' : new Date(radiationSafety.effectiveDate).toLocaleDateString('en-GB')}</td>
                                        <td>{isNaN(Date.parse(radiationSafety.reviewDate)) ? '' : new Date(radiationSafety.reviewDate).toLocaleDateString('en-GB')}</td>


                                        <td>{radiationSafety.comments}</td>
                                        <td>{radiationSafety.activeYorN}</td>

                                        <td>
                                            <LinkContainer to={`/radiationSafety/${radiationSafety._id}/edit`}>
                                                <Button variant='outline-info' className='btn-sm mx-2'>
                                                    <FaEdit /> Edit Item
                                                </Button>
                                            </LinkContainer>

                                            <Button
                                                variant='warning'
                                                className='btn-sm'
                                                onClick={() => deleteHandler(radiationSafety._id)}
                                            >
                                                <FaTrash className='text-end' style={{ color: 'white' }} /> Delete
                                            </Button>

                                        </td>





                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <Paginate pages={data.pages} page={data.page} isAdmin={false} />
                </>
            )}
        </>
    );
};

export default RadiationSafetyListScreen;
