import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/bootstrap.custom.css';
import './assets/styles/index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ResetScreen from './screens/ResetScreen';
import ShippingScreen from './screens/ShippingScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import ProfileScreen from './screens/ProfileScreen';
import WorklistEditActivityScreen from './screens/WorklistEditActivityScreen';
import WorklistScreen from './screens/WorklistScreen';
import WorklistEditItemScreen from './screens/WorklistEditItemScreen';
import WorklistEditScreen from './screens/WorklistEditScreen';

import PrimaryNotificationEditActivityScreen from './screens/PrimaryNotificationEditActivityScreen';
import PrimaryNotificationScreen from './screens/PrimaryNotificationScreen';
import PrimaryNotificationEditItemScreen from './screens/PrimaryNotificationEditItemScreen';
import PrimaryNotificationEditScreen from './screens/PrimaryNotificationEditScreen';


import OrderListScreen from './screens/admin/OrderListScreen';
import ProductListScreen from './screens/admin/ProductListScreen';
import ProductEditScreen from './screens/admin/ProductEditScreen';
import UserListScreen from './screens/admin/UserListScreen';
import UserEditScreen from './screens/admin/UserEditScreen';
import store from './store';
import { Provider } from 'react-redux';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import ActivityListScreen from './screens/admin/ActivityListScreen';
import ActivityEditScreen from './screens/admin/ActivityEditScreen';
import ClinicalSiteListScreen from './screens/admin/ClinicalSiteListScreen';
import ClinicalSiteEditScreen from './screens/admin/ClinicalSiteEditScreen';

import ClinicalSCommitmentListScreen from './screens/admin/ClinicalSCommitmentListScreen';
import ClinicalSCommitmentEditScreen from './screens/admin/ClinicalSCommitmentEditScreen';

import UserClinicalSCommitmentListScreen from './screens/UserClinicalCommitmentListScreen';
import UserClinicalSCommitmentEditScreen from './screens/UserClinicalSCommitmentEditScreen';

import RadiationSafetyListScreen from './screens/admin/RadiationSafetyListScreen';
import RadiationSafetyEditScreen from './screens/admin/RadiationSafetyEditScreen';

import UserRadiationSafetyListScreen from './screens/UserRadiationSafetyListScreen';
import UserRadiationSafetyEditScreen from './screens/UserRadiationSafetyEditScreen';
//<Route index={true} path='/' element={<HomeScreen />} /> 

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index={true} path='/login' element={<LoginScreen />} />





      <Route path='/product/:id' element={<ProductScreen />} />
      <Route path='/cart' element={<CartScreen />} />
      <Route path='/login' element={<LoginScreen />} />
      <Route path='/register' element={<RegisterScreen />} />
      <Route path='/TesetPassword' element={<RegisterScreen />} />
      <Route path='/ResetPassword' element={<ResetScreen />} />




      {/* Registered users */}
      <Route path='' element={<PrivateRoute />}>
        <Route path='/' element={<HomeScreen />} />
        <Route path='/search/:keyword' element={<HomeScreen />} />
        <Route path='/page/:pageNumber' element={<HomeScreen />} />
        <Route
          path='/search/:keyword/page/:pageNumber'
          element={<HomeScreen />}
        />


        <Route path='/shipping' element={<ShippingScreen />} />
        <Route path='/payment' element={<PaymentScreen />} />
        <Route path='/placeorder' element={<PlaceOrderScreen />} />
        <Route path='/order/:id' element={<OrderScreen />} />
        <Route path='/profile' element={<ProfileScreen />} />
        <Route path='/worklist' element={<WorklistScreen />} />
        <Route path='/worklist/:id' element={<WorklistEditActivityScreen />} />
        <Route path='/worklist/:id/activity/:UKey' element={<WorklistEditItemScreen />} />
        <Route path='/worklist/:id/edit' element={<WorklistEditScreen />} />

        <Route path='/primaryNotification' element={<PrimaryNotificationScreen />} />
        <Route path='/primaryNotification/:id' element={<PrimaryNotificationEditActivityScreen />} />
        <Route path='/primaryNotification/:id/activity/:UKey' element={<PrimaryNotificationEditItemScreen />} />
        <Route path='/primaryNotification/:id/edit' element={<PrimaryNotificationEditScreen />} />



        <Route path='/clinicalscommitmentlist' element={<UserClinicalSCommitmentListScreen />} />
        <Route
          path='/clinicalscommitmentlist/:pageNumber'
          element={<UserClinicalSCommitmentListScreen />}
        />
        <Route path='/clinicalscommitment/:id/edit' element={<UserClinicalSCommitmentEditScreen />} />


        <Route path='/radiationsafetylist' element={<UserRadiationSafetyListScreen />} />
        <Route
          path='/radiationsafetylist/:pageNumber'
          element={<UserRadiationSafetyListScreen />}
        />
        <Route path='/radiationsafety/:id/edit' element={<UserRadiationSafetyEditScreen />} />

      </Route>



      {/* ...   */}



      {/* Admin users */}
      <Route path='' element={<AdminRoute />}>
        <Route path='/admin/orderlist' element={<OrderListScreen />} />
        <Route path='/admin/productlist' element={<ProductListScreen />} />
        <Route
          path='/admin/productlist/:pageNumber'
          element={<ProductListScreen />}
        />

        <Route path='/admin/activitylist' element={<ActivityListScreen />} />
        <Route
          path='/admin/activitylist/:pageNumber'
          element={<ActivityListScreen />}
        />
        <Route path='/admin/clinicalsitelist' element={<ClinicalSiteListScreen />} />
        <Route
          path='/admin/clinicalsitelist/:pageNumber'
          element={<ClinicalSiteListScreen />}
        />
        <Route path='/admin/clinicalscommitmentlist' element={<ClinicalSCommitmentListScreen />} />
        <Route
          path='/admin/clinicalscommitmentlist/:pageNumber'
          element={<ClinicalSCommitmentListScreen />}
        />

        <Route path='/admin/radiationsafetylist' element={<RadiationSafetyListScreen />} />
        <Route
          path='/admin/radiationsafetylist/:pageNumber'
          element={<RadiationSafetyListScreen />} />

        <Route path='/admin/radiationsafety/:id/edit' element={<RadiationSafetyEditScreen />} />





        <Route path='/admin/userlist' element={<UserListScreen />} />
        <Route path='/admin/product/:id/edit' element={<ProductEditScreen />} />

        <Route path='/admin/activity/:id/edit' element={<ActivityEditScreen />} />
        <Route path='/admin/clinicalsite/:id/edit' element={<ClinicalSiteEditScreen />} />
        <Route path='/admin/clinicalscommitment/:id/edit' element={<ClinicalSCommitmentEditScreen />} />



        <Route path='/admin/user/:id/edit' element={<UserEditScreen />} />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <PayPalScriptProvider deferLoading={true}>
          <RouterProvider router={router} />
        </PayPalScriptProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
