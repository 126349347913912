import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import Message from './Message';
import Loader from './Loader';
import Paginate from './PaginateCommitments';
import { useDispatch, useSelector } from 'react-redux';

import { useProfileMutation, useGetUserProDetailsQuery } from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';

import { toast } from 'react-toastify';




const MyProfile = () => {

    const { userInfo } = useSelector((state) => state.auth);
    const { pageNumber } = useParams();

    const {
        data,
        isLoading,
        error,
        refetch,
    } = useGetUserProDetailsQuery(userInfo._Id);




    return (
        <>

            <Row className='align-items-center'>
                <Col>
                    <h8>Profile</h8>
                </Col>

            </Row>


            {isLoading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error.data.message}</Message>
            ) : (
                <>
                    <Table striped bordered hover responsive className='table-sm' style={{ fontSize: '0.8rem', padding: '0.1rem' }}>
                        <thead>
                            <tr>

                                <th style={{ width: '10%' }}>Name</th>
                                <th style={{ width: '10%' }}>Effective Date</th>
                                <th style={{ width: '10%' }}>Contract</th>

                            </tr>
                        </thead>
                        <tbody>


                            <tr key={data._id}>

                                <td style={{ width: '10%' }}>{data.name}</td>

                                <td>{isNaN(Date.parse(data.effectiveDate)) ? '' : new Date(data.effectiveDate).toLocaleDateString('en-GB')}</td>
                                <td style={{ width: '10%' }}>{data.contracttype}</td>

                            </tr>

                        </tbody>
                    </Table>
                    <Paginate pages={data.pages} page={data.page} isAdmin={true} />
                </>
            )}
        </>
    );
};

export default MyProfile;
