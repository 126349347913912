import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import Paginate from '../../components/PaginateActivity';
import {
    useGetActivitiesQuery,
    useDeleteActivityMutation,
    useCreateActivityMutation,
} from '../../slices/activitiesApiSlice';
import { toast } from 'react-toastify';

const ActivityListScreen = () => {
    const { pageNumber } = useParams();

    const { data, isLoading, error, refetch } = useGetActivitiesQuery({
        pageNumber,
    });

    const [deleteActivity, { isLoading: loadingDelete }] =
        useDeleteActivityMutation();

    const deleteHandler = async (id) => {
        if (window.confirm(`Are you sure  ${id}`)) {
            try {
                await deleteActivity(id);
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    const [createActivity, { isLoading: loadingCreate }] =
        useCreateActivityMutation();

    const createActivityHandler = async () => {
        if (window.confirm('Are you sure you want to create a new activity?')) {
            try {
                await createActivity();
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    return (
        <>
            <Row className='align-items-center'>
                <Col>
                    <h1>Activities</h1>
                </Col>
                <Col className='text-end'>
                    <Button className='my-3' onClick={createActivityHandler}>
                        <FaPlus /> Create Activity
                    </Button>
                </Col>
            </Row>

            {loadingCreate && <Loader />}
            {loadingDelete && <Loader />}
            {isLoading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error.data.message}</Message>
            ) : (
                <>
                    <Table striped bordered hover responsive className='table-sm'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Activity Name</th>
                                <th>Support Or Clinical</th>
                                <th>Active Y or N</th>
                                <th>Paid Y or N</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.activities.map((activity) => (
                                <tr key={activity._id}>
                                    <td>{activity._id}</td>
                                    <td>{activity.activityName}</td>
                                    <td>{activity.supportOrClinical}</td>
                                    <td>{activity.activeYorN}</td>
                                    <td>{activity.paidYorN}</td>


                                    <td>
                                        <LinkContainer to={`/admin/activity/${activity._id}/edit`}>
                                            <Button variant='outline-info' className='btn-sm mx-2'>
                                                <FaEdit /> Edit Item
                                            </Button>
                                        </LinkContainer>

                                        <Button
                                            variant='warning'
                                            className='btn-sm'
                                            onClick={() => deleteHandler(activity._id)}
                                        >
                                            <FaTrash className='text-end' style={{ color: 'white' }} /> Delete
                                        </Button>




                                    </td>
                                    {/*
                                    
                                    <td>
                                        <LinkContainer to={`/admin/activity/${activity._id}/edit`}>
                                            <Button variant='light' className='btn-sm mx-2'>
                                                <FaEdit />
                                            </Button>
                                        </LinkContainer>
                                        <Button
                                            variant='danger'
                                            className='btn-sm'
                                            onClick={() => deleteHandler(activity._id)}
                                        >
                                            <FaTrash style={{ color: 'white' }} />
                                        </Button>
                                    </td>
                            */}





                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Paginate pages={data.pages} page={data.page} isAdmin={true} />
                </>
            )}
        </>
    );
};

export default ActivityListScreen;
