import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { FaEdit, FaPlus, FaTrash, FaTimes, } from 'react-icons/fa';

import { toast } from 'react-toastify';
import Message from '../components/Message';
import Loader from '../components/Loader';

import { useGetMyWorklistsQuery, useCreateWorklistMutation } from '../slices/worklistsApiSlice';
import { setCredentials } from '../slices/authSlice';
import { Link } from 'react-router-dom';
import MyClinicalSCommitments from '../components/MyClinicalCommitments';
import MyProfile from '../components/MyProfile';
import { calcTotal } from '../utils/calcTotals.js'


//#f5f5dc
const dropdownStyle = {
    backgroundColor: '#F5F5F5'    //'#F0E68C'                 //'lightyellow',
    // Add other styles as needed
};
const textStyle = {
    backgroundColor: '#f5f5dc'
    // Add other styles as needed
};


const WorklistScreen = () => {




    const { userInfo } = useSelector((state) => state.auth);

    //const { pageNumber } = useParams();
    const { myUserId } = userInfo._id

    console.log('userInfo._id', userInfo._id);



    const { data: worklists, isLoading, error, refetch } = useGetMyWorklistsQuery();

    const dispatch = useDispatch();


    const [createWorklist, { isLoading: loadingCreate }] =
        useCreateWorklistMutation();

    const createWorklistHandler = async () => {
        if (window.confirm('Are you sure you want to create a new Worklist?')) {
            try {
                await createWorklist();
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };



    return (
        <>
            <Link to='/' className='btn btn-light mb-4'>
                Go Back
            </Link>


            <Row>
                <Col md={12}>

                    <Row><Col md={4}>
                        <h2>My Worklist</h2>
                    </Col>

                    </Row>
                    <Row>

                        <Col md={4}>

                            <MyProfile />
                        </Col>

                        <Col md={4}>


                        </Col>

                        <Col md={4} className='text-end'>

                            <MyClinicalSCommitments />
                        </Col>
                    </Row>






                    {isLoading ? (
                        <Loader />
                    ) : error ? (
                        <Message variant='danger'>
                            {error?.data?.message || error.error}
                        </Message>
                    ) : (

                        <>

                            {worklists.length === 0 ? (
                                <Col md={12} className='text-end'>
                                    <Button className='my-3' onClick={createWorklistHandler}>
                                        <FaPlus /> Create Worklist
                                    </Button>
                                </Col>

                            ) : (

                                <Col md={12} className='text-end'>
                                    <th> </th>
                                </Col>


                            )}




                            < Table striped bordered hover responsive className='table-sm'>
                                <thead>
                                    <tr>
                                        <th>Worklist Name</th>
                                        <th>Created</th>

                                        <th>Manager Approved</th>
                                        <th>HR Approved</th>
                                        <th>Description</th>
                                        <th style={{ backgroundColor: 'lightyellow' }}>TOTAL HOURS</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {worklists.map((worklist) => (
                                        <tr key={worklist._id}>
                                            <td>{worklist.worklistName}</td>
                                            <td>{worklist.createdAt.substring(0, 10)}</td>


                                            <td>
                                                {worklist.isManagerApproved ? (
                                                    worklist.manApprovedAt.substring(0, 10)
                                                ) : (
                                                    <FaTimes style={{ color: 'red' }} />
                                                )}
                                            </td>
                                            <td>
                                                {worklist.isAdminApproved ? (
                                                    worklist.adminApprovedAt.substring(0, 10)
                                                ) : (
                                                    <FaTimes style={{ color: 'red' }} />
                                                )}
                                            </td>

                                            <td>{worklist.description}</td>

                                            <td style={{ backgroundColor: 'lightyellow', width: '10%' }}>{calcTotal(worklist.worklistItems)}</td>
                                            <td>

                                                <LinkContainer to={`/worklist/${worklist._id}/edit`}>
                                                    <Button variant='outline-info' className='btn-sm mx-2'>
                                                        <FaEdit /> Edit
                                                    </Button>
                                                </LinkContainer>

                                                <LinkContainer to={`/worklist/${worklist._id}`}>
                                                    <Button variant='outline-info' className='btn-sm mx-2'>
                                                        <td style={{ backgroundColor: 'Moccasin', width: '10%' }}> <FaEdit /> Edit Activities</td>
                                                    </Button>
                                                </LinkContainer>


                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </Col>
            </Row >
        </>
    );
};

export default WorklistScreen;
