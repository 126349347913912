function addDecimals(num) {
    return (Math.round(num * 100) / 100).toFixed(2);
}

export function calcTotal(primaryNotificationItems) {

    console.log('primaryNotificationItems', primaryNotificationItems.wte);
    
/*
    const itemsTotalWTE = primaryNotificationItems.reduce((total, item) => {
        // const startTimeInMinutes = item.startHour * 60 + item.startMin;
        //const endTimeInMinutes = item.endHour * 60 + item.endMin;
        return total + (item.wte);
    }, 0);
*/

    const itemsTotalWTE = primaryNotificationItems.reduce((total, item) => {
        if (item.activeYorN !== "No") {
            // Add the item's WTE to the total only if activeYorN is not "No"
            return total + item.wte;
        } else {
            // Otherwise, return the current total unchanged
            return total;
        }
    }, 0);


    //const hours = Math.floor(itemsTotalMinutes / 60);
    //const minutes = itemsTotalMinutes % 60;

    ////const itemsTotal = addDecimals(itemsTotalMinutes / 60); // If you need the total in hours with decimals
    //const itemsTotal = `${hours}:${minutes}`
    //// const totalTime = (Number(itemsTotal) ).toFixed(2);
    
    return +addDecimals(itemsTotalWTE); //{ itemsTotal, hours, minutes };





}