import { ACTIVITIES_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const activitiesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getActivities: builder.query({
            query: ({ keyword, pageNumber }) => ({
                url: ACTIVITIES_URL,
                params: { keyword, pageNumber },
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Activities'],
        }),

        getActivitiesNoPage: builder.query({
            query: () => ({
                url: `${ACTIVITIES_URL}/list`,

            }),
            keepUnusedDataFor: 5,
            providesTags: ['Activities'],
        }),




        getActivityDetails: builder.query({
            query: (activityId) => ({
                url: `${ACTIVITIES_URL}/${activityId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        createActivity: builder.mutation({
            query: () => ({
                url: `${ACTIVITIES_URL}`,
                method: 'POST',
            }),
            invalidatesTags: ['Activity'],
        }),
        updateActivity: builder.mutation({
            query: (data) => ({
                url: `${ACTIVITIES_URL}/${data.activityId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Activities'],
        }),
        deleteActivity: builder.mutation({
            query: (activityId) => ({
                url: `${ACTIVITIES_URL}/${activityId}`,
                method: 'DELETE',
            }),
            providesTags: ['Activity'],
        }),


    }),
});

export const {
    useGetActivitiesQuery,
    useGetActivitiesNoPageQuery,
    useGetActivityDetailsQuery,
    useCreateActivityMutation,
    useUpdateActivityMutation,
    useDeleteActivityMutation,

} = activitiesApiSlice;
