import React, { useEffect, useState , useRef } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { FaEdit, FaPlus, FaTrash, FaTimes } from 'react-icons/fa';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import Message from '../components/Message.jsx';
import Loader from '../components/Loader.jsx';
import TimePicker from '../components/TimePicker.jsx';

import {
    useGetMyPrimaryNotificationsQuery, useCreatePrimaryNotificationMutation, useGetPrimaryNotificationDetailsQuery, useGetPrimaryNotificationItemsQuery,
    useUpdatePrimaryNotificationMutation, useUpdatePrimaryNotificationActivityMutation,
} from '../slices/primaryNotificationApiSlice.js';

import { useGetMyClinicalSCommitmentsQuery } from '../slices/clinicalSCommitmentsApiSlice.js';

import { setCredentials } from '../slices/authSlice.js';
import FormContainer from '../components/FormContainer.jsx';

import {
    useGetGradesNoPageQuery,
    useDeleteGradeMutation,
    useCreateGradeMutation,
} from '../slices/gradesApiSlice.js';

import {
    useGetDirectoratesNoPageQuery,
    
} from '../slices/directoratesApiSlice.js';

import {
    useGetCostcentresNoPageQuery,
    
} from '../slices/costcentresApiSlice.js';


import {
    useGetClinicalSitesNPQuery,

} from '../slices/clinicalSitesApiSlice.js';


//

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import '../../src/components/TimePicker.css'


import styled from 'styled-components';


// Styled component for hiding the carousel on small screens
const HiddenCarousel = styled.div`
@media screen and (max-width: 767px) {
  display: none;
}
`;

const isWideScreen = window.innerWidth > 767;

const containerStyle = {
  display: 'flex',
  flexDirection: isWideScreen ? 'row' : 'column',
  justifyContent: isWideScreen ? 'space-between' : 'center',
  flexWrap: isWideScreen ? 'wrap' : 'nowrap',
};



{/*style={{  width: '200px', flexBasis: 'calc(90% - 5px)', marginBottom: '10px' }}*/}

const textboxStyle = {
    display: 'flex',
    flexDirection: isWideScreen ? 'row' : 'column',
    flexBasis: isWideScreen ? 'calc(90% )' : 'calc(90% - 5px)',
    width: isWideScreen ? '200px' : '100%', // Set width to 200px on large screens, and 100% on small screens
  
    justifyContent: isWideScreen ? 'space-between' : 'center',
    flexWrap: isWideScreen ? 'wrap' : 'nowrap',
    
    // Add background color based on text, dropdown, or date field
    
    backgroundColor: 'lightyellow'  // Set background color for text fields
                    
  };

  const dropdownboxStyle = {
    display: 'flex',
    flexDirection: isWideScreen ? 'row' : 'column',
    flexBasis: isWideScreen ? 'calc(90% )' : 'calc(90% - 5px)',
    width: isWideScreen ? '200px' : '100%', // Set width to 200px on large screens, and 100% on small screens
  
    justifyContent: isWideScreen ? 'space-between' : 'center',
    flexWrap: isWideScreen ? 'wrap' : 'nowrap',
    
    // Add background color based on text, dropdown, or date field
    
    backgroundColor: 'lightgray'  // Set background color for dropdown fields
                     
  };

  const dateboxStyle = {
    display: 'flex',
    flexDirection: isWideScreen ? 'row' : 'column',
    flexBasis: isWideScreen ? 'calc(90% )' : 'calc(90% - 5px)',
    width: isWideScreen ? '200px' : '100%', // Set width to 200px on large screens, and 100% on small screens
  
    justifyContent: isWideScreen ? 'space-between' : 'center',
    flexWrap: isWideScreen ? 'wrap' : 'nowrap',
    
    // Add background color based on text, dropdown, or date field
    
    backgroundColor: 'lightyellow'  // Set background color for date fields
  };


//#f5f5dc
const dropdownStyle = {
    backgroundColor: '#F5F5F5'    //'#F0E68C'                 //'lightyellow',
    // Add other styles as needed
};
const textStyle = {
    backgroundColor: '#f5f5dc'
    // Add other styles as needed
};


const PrimaryNotificationEditItemScreen = () => {

    const { pageNumber } = useParams();
    const { data: myClinicalCommitmants, isLoading: isLoadingCC, error: errorCC, refetch: refetchCC } = useGetMyClinicalSCommitmentsQuery({
        pageNumber,
    });



    const { userInfo } = useSelector((state) => state.auth);
    const updateButtonRef = useRef(null);

    // console.log('userInfo._id', userInfo._id);



    //const { data: primaryNotifications, isLoading, error } = useGetMyPrimaryNotificationsQuery();

    //const { id: primaryNotificationId } = useParams();
    const { id: primaryNotificationIdAndCode, UKey: UKey } = useParams();
    const [primaryNotificationId, primaryNotificationCode,pnWteCode] = primaryNotificationIdAndCode.split(':');

    console.log('id: primaryNotificationId', primaryNotificationId);
    console.log(' UKey:', UKey);


    const [serviceArea, setServiceArea] = useState('');
    const [hgChoNationalFunction, setHgChoNationalFunction] = useState('');
    const [divisionCareGroup, setDivisionCareGroup] = useState('');

    const [location, setLocation] = useState('');
    const [fundingStream, setFundingStream] = useState('');
    const [fundsAllocation, setFundsAllocation] = useState('');
    const [titleofInitiative, setTitleofInitiative] = useState('');
    const [subInitiative, setSubInitiative] = useState('');
    const [gradeCode, setGradeCode] = useState('');
    const [grade, setGrade] = useState('');

   
    const [contractType, setContractType] = useState('');
    const [wte, setWte] = useState(0);
    const [swpiLogNo, setSwpiLogNo] = useState('');
    const [pnNo, setPnNo] = useState('');
    const [activeYorN, setActiveYorN] = useState('');

    const [comments, setComments] = useState("");

    const [eccNumber, setEccNumber] = useState("");
    const [startDate, setStartDate] = useState("");
    const [directorate, setDirectorate] = useState("");
    const [ccname, setCcname] = useState("");
    const [areaService, setAreaService] = useState("");
    const [lineStatus, setLineStatus] = useState("");

    const [sapPostNumber,setSapPostNumber]= useState("");
    const [stormPostNumber, setStormPostNumber]= useState("");
    const [dateApprovedByGroup, setDateApprovedByGroup]= useState("");
	const [dateSentToGroup, setDateSentToGroup]= useState("");
    const [dateECCApproved, setDateECCApproved]= useState("");
    const [dateRecived, setDateRecived]= useState("");
    const [dateECCRaised	, setDateECCRaised]= useState("");			
    const [lineComment, setLineComment]= useState("");

    const [firstName, setFirstName]= useState("");
    const [surName, setSurName]= useState("");
    


    const {
        data: theItem,
        refetch,
        isLoading,
        error,
    } = useGetPrimaryNotificationItemsQuery({ primaryNotificationID: primaryNotificationId, activityID: UKey });

    const { data: gradesdata, isLoading: isLoadingAC, error: errorAC, refetch: refetchAC } = useGetGradesNoPageQuery();

    const { data: directoratesdata, isLoading: isLoadingD, error: errorD, refetch: refetchD } = useGetDirectoratesNoPageQuery();
    const { data: costcentresdata, isLoading: isLoadingBC, error: errorBC, refetch: refetchBC } = useGetCostcentresNoPageQuery();

    const { data: clinicalSitedata, isLoading: isLoadingCS, error: errorCS, refetch: refetchCS } = useGetClinicalSitesNPQuery();
    //console.log('activitydata ', activitydata);

    const dispatch = useDispatch();


    const [createPrimaryNotification, { isLoading: loadingCreate }] =
        useUpdatePrimaryNotificationActivityMutation(primaryNotificationId);

    const createPrimaryNotificationHandler = async () => {
        if (window.confirm('Are you sure you want to create a new Primary Notification Item?')) {
            try {
                await createPrimaryNotification();
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    //_____________________________________________________________________

    const [updatePrimaryNotificationItem, { isLoading: loadingUpdate }] =
        useUpdatePrimaryNotificationActivityMutation();



    const navigate = useNavigate();

    const submitHandler = async (e) => {
        e.preventDefault();


        const details = {

            serviceArea,
            hgChoNationalFunction,
            divisionCareGroup,
            location,
            fundingStream,
            fundsAllocation,
            titleofInitiative,
            subInitiative,
            gradeCode,
            grade,
            contractType,
            wte,
            swpiLogNo,
            pnNo,
            activeYorN,
            comments,
            eccNumber,
            startDate,
            directorate,
            ccname,
            areaService,
            lineStatus,

            sapPostNumber,
            stormPostNumber,
            dateApprovedByGroup,
            dateSentToGroup,
            dateECCApproved,
            dateRecived,
            dateECCRaised,				
            lineComment,
            firstName,
            surName,

        };
        console.log('details', details);

        console.log('UKey ', UKey)
        try {
            await updatePrimaryNotificationItem({
                primaryNotificationId,
                details,
                UKey,

            }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
            toast.success('Item updated');
            refetch();
            navigate(`/primaryNotification/${primaryNotificationId}:${pnWteCode}`);
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    useEffect(() => {

        if (activeYorN === "No" && updateButtonRef.current) {
            updateButtonRef.current.click();
        }

        if (theItem) {

            setServiceArea(theItem.serviceArea);
            setHgChoNationalFunction(theItem.hgChoNationalFunction);
            setDivisionCareGroup(theItem.divisionCareGroup);
            setLocation(theItem.location);
            setFundingStream(theItem.fundingStream);
            setFundsAllocation(theItem.fundsAllocation);
            setTitleofInitiative(theItem.titleofInitiative);
            setSubInitiative(theItem.subInitiative);
            setGradeCode(theItem.gradeCode);
            setGrade(theItem.grade);
            setContractType(theItem.contractType);
            setWte(theItem.wte);
            setSwpiLogNo(theItem.swpiLogNo);
            setPnNo(theItem.pnNo);
            setActiveYorN(theItem.activeYorN);
            setComments(theItem.comments);

            setEccNumber(theItem.eccNumber);
            setStartDate(theItem.startDate);
            setDirectorate(theItem.directorate);
            setCcname(theItem.ccname);            
            setAreaService(theItem.areaService);
            setLineStatus(theItem.lineStatus);

            setSapPostNumber(theItem.sapPostNumber)
            setStormPostNumber(theItem.stormPostNumber)
            setDateApprovedByGroup(theItem.dateApprovedByGroup)
            setDateSentToGroup(theItem.dateSentToGroup)
            setDateECCApproved(theItem.dateECCApproved)
            setDateRecived(theItem.dateRecived)
            setDateECCRaised(theItem.dateECCRaised)				
            setLineComment(theItem.lineComment)

            setFirstName(theItem.firstName)
            setSurName(theItem.surName)

        }
    }, [theItem, activeYorN]);


    const deleteHandler = async (id) => {
        if (window.confirm(`Are you sure  ${id}`)) {            
                 setActiveYorN("No");
                 
                
            } 
           

           
        };
    //____________________________________________________________________//{`${activityList._id}:${activityList.activityName}`}

//Go Back {window.innerWidth}

    return (
        <>
            <Row>
                <Col md={12}>
                
                <div>
                
                {/*hide SearchBox on small screens */}
            <HiddenCarousel>
              
              <Link to={`/primaryNotification/${primaryNotificationId}:${pnWteCode}`} className='btn btn-light my-3'
                    style={{  '@media screen and (max-width: 767px)': { display: 'none' } }}>
                        Go Back 
                    </Link> 

            </HiddenCarousel>          


              
              
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: '1' }}>

               

                    {primaryNotificationId ? (
                        <div style={{ display: 'inline-block' }}>
                            <h4 style={{ display: 'inline-block', marginLeft: '10px' }}>Primary Notification {primaryNotificationCode}</h4>
                            <h6 style={{ display: 'inline-block', marginLeft: '10px' }}>Line Item {UKey ? (
                            <span>{UKey}</span>
                            ) : (
                            <span>Loading...</span>
                            )}</h6>
                            </div>
                        ) : (
                            <h3>Loading...</h3>
                            )}
                    </div>

                   
                </div>





                   
                    

                


                   
                    

                    {loadingUpdate && <Loader />}
                    {isLoadingAC && <Loader />}
                    {isLoadingD && <Loader />}
                    {isLoadingBC && <Loader />}
                    {isLoadingCS && <Loader />}

                    {isLoading ? (
                        <Loader />
                    ) : error ? (
                        <Message variant='danger'>{error.data.message}</Message>
                    ) : (

                        <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>
                            <Form onSubmit={submitHandler}>

                                <Row>

                                

                                    <Col md={4}>
                                    
                                    <div style={{ border: '1px solid green', backgroundColor: '#F0F8FF', padding: '10px' }}>
                                    <h2>Primary Notification </h2>

                                        <Form.Group controlId='serviceArea'>
                                            <Form.Label>Enter ServiceArea</Form.Label>
                                            <Form.Control
                                                as='select'
                                                placeholder='Enter serviceArea'
                                                value={serviceArea}

                                                onChange={(e) => setServiceArea(e.target.value)} style={dropdownStyle} >
                                                <option value={serviceArea}>{serviceArea}</option>

                                                <option value='Acute Services'>Acute Services</option>
                                                <option value='Community Services'>Community Services</option>
                                                <option value='HWB, Corporate and National'>HWB, Corporate and National</option>

                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId='hgChoNationalFunction'>
                                            <Form.Label>Enter HG/CHO/National Function</Form.Label>
                                            <Form.Control
                                                as='select'
                                                placeholder='Enter HG/CHO/National Function'
                                                value={hgChoNationalFunction}

                                                onChange={(e) => setHgChoNationalFunction(e.target.value)} style={dropdownStyle} >
                                                <option value={hgChoNationalFunction}>{hgChoNationalFunction}</option>

                                                <option value='Childrens Health Ireland'>Children's Health Ireland</option>
                                                <option value='CHO 1'>CHO 1</option>
                                                <option value='CHO 2'>CHO 2</option>
                                                <option value='CHO 3'>CHO 3</option>
                                                <option value='CHO 4'>CHO 4</option>
                                                <option value='CHO 5'>CHO 5</option>
                                                <option value='CHO 6'>CHO 6</option>
                                                <option value='CHO 7'>CHO 7</option>
                                                <option value='CHO 8'>CHO 8</option>
                                                <option value='CHO 9'>CHO 9</option>
                                                <option value='Corporate'>Corporate</option>
                                                <option value='Dublin Midlands Hospital Group'>Dublin Midlands Hospital Group</option>
                                                <option value='Health and Wellbeing'>Health and Wellbeing</option>
                                                <option value='Ireland East Hospital Group'>Ireland East Hospital Group</option>
                                                <option value='National Ambulance Service'>National Ambulance Service</option>
                                                <option value='other Acute Services'>other Acute Services</option>
                                                <option value='other Community Services'>other Community Services</option>
                                                <option value='RCSI Hospitals Group'>RCSI Hospitals Group</option>
                                                <option value='Saolta University Hospital Care'>Saolta University Hospital Care</option>
                                                <option value='South/South West Hospital Group'>South/South West Hospital Group</option>
                                                <option value='University of Limerick Hospital Group'>University of Limerick Hospital Group</option>


                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId='divisionCareGroup'>
                                            <Form.Label>Division/Care Group</Form.Label>
                                            <Form.Control
                                                as='select'
                                                placeholder='Division/Care Group'
                                                value={divisionCareGroup}

                                                onChange={(e) => setDivisionCareGroup(e.target.value)} style={dropdownStyle} >
                                                <option value={divisionCareGroup}>{divisionCareGroup}</option>
                                                <option value='Acute Services'>Acute Services</option>




                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId='location'>
                                            <Form.Label>Location</Form.Label>
                                            <Form.Control
                                                as='select'
                                                placeholder='Location'
                                                value={Location}

                                                onChange={(e) => setLocation(e.target.value)} style={dropdownStyle} >
                                                <option value={location}>{location}</option>

                                                <option value='Beaumont'>Beaumont</option>
                                                <option value='Cavan General'>Cavan General</option>
                                                <option value='Connolly'>Connolly</option>
                                                <option value='Hospital Group HQ'>Hospital Group HQ</option>
                                                <option value='Louth County'>Louth County</option>
                                                <option value='Monaghan'>Monaghan</option>
                                                <option value='Our Lady of Lourdes'>Our Lady of Lourdes</option>
                                                <option value='Rotunda'>Rotunda</option>




                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId='fundingStream'>
                                            <Form.Label>Funding Narrative</Form.Label>

                                            <Form.Control
                                                type='text'
                                                placeholder='NFSD LFSD ExternalFSD and year'
                                                value={fundingStream}
                                                onChange={(e) => setFundingStream(e.target.value)} style={textStyle}
                                            ></Form.Control>
                                        </Form.Group>


                                        <Form.Group controlId='fundsAllocation'>
                                            <Form.Label>Funds Allocation</Form.Label>

                                            <Form.Control
                                                type='text'
                                                placeholder='fundsAllocation'
                                                value={fundsAllocation}
                                                onChange={(e) => setFundsAllocation(e.target.value)} style={textStyle}
                                            ></Form.Control>
                                        </Form.Group>




                                        <Form.Group controlId='titleofInitiative'>
                                            <Form.Label>Title of Initiative</Form.Label>

                                            <Form.Control
                                                type='text'
                                                placeholder='titleofInitiative'
                                                value={titleofInitiative}
                                                onChange={(e) => setTitleofInitiative(e.target.value)} style={textStyle}
                                            ></Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId='subInitiative'>
                                            <Form.Label>Sub_Initiative</Form.Label>

                                            <Form.Control
                                                as='textarea'
                                                rows={4}
                                                placeholder='subInitiative'
                                                value={subInitiative}
                                                onChange={(e) => setSubInitiative(e.target.value)} style={textStyle}
                                            ></Form.Control>
                                        </Form.Group>
                                        
                                      </div>  
                                    </Col>

                                    {/* Second Column **************************************************************************************/}
                                    
                                    <Col md={3}>

                                    <div style={{ border: '1px solid green', backgroundColor: '#F0F8FF', padding: '10px' }}>
                                    <h2>Details</h2>

                                    <Form.Group controlId='grade'>
                                            <Form.Label>Grade</Form.Label>
                                            <Form.Control

                                                as='select'
                                                placeholder='Grade'
                                                value={grade}

                                                //onChange={(e) => setActivityName(e.target.value)} style={dropdownStyle} 
                                                onChange={(e) => {
                                                    const selectedOption = e.target.value;
                                                    console.log('selectedOption', selectedOption);
                                                    const [newGradeCode, newGrade] = selectedOption.split(':');
                                                    setGradeCode(newGradeCode);
                                                    setGrade(newGrade);
                                                }}
                                            >


                                                <option value={grade}>{grade}</option>

                                                {gradesdata &&
                                                    gradesdata
                                                        .slice() // Create a shallow copy of the array to avoid mutating the original array
                                                        .sort((a, b) => a.grade.localeCompare(b.grade)) // Sort by activityName
                                                        .map((gradeList) => (
                                                            <option key={gradeList._id} value={`${gradeList.code}:${gradeList.grade}`}>
                                                                {gradeList.grade}
                                                            </option>
                                                        ))}




                                            </Form.Control>
                                        </Form.Group>


                                        <Form.Group controlId='grade'>
                                            <Form.Label>Grade Code</Form.Label>
                                            <Form.Control

                                                as='select'
                                                placeholder='Grade Code'
                                                value={gradeCode}

                                                //onChange={(e) => setActivityName(e.target.value)} style={dropdownStyle} 
                                                onChange={(e) => {
                                                    const selectedOption = e.target.value;
                                                    console.log('selectedOption', selectedOption);
                                                    const [newGradeCode, newGrade] = selectedOption.split(':');
                                                    setGradeCode(newGradeCode);
                                                    setGrade(newGrade);
                                                }}
                                            >


                                                <option value={gradeCode}>{gradeCode}</option>

                                                {gradesdata &&
                                                    gradesdata
                                                        .slice() // Create a shallow copy of the array to avoid mutating the original array
                                                        .sort((a, b) => a.code.localeCompare(b.code)) // Sort by activityName
                                                        .map((gradeList) => (
                                                            <option key={gradeList._id} value={`${gradeList.code}:${gradeList.grade}`}>
                                                                {gradeList.code}
                                                            </option>
                                                        ))}




                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId='contractType'>
                                            <Form.Label>Enter Contract Type</Form.Label>
                                            <Form.Control
                                                as='select'
                                                placeholder='Enter Contract Type'
                                                value={contractType}
                                                onChange={(e) => setContractType(e.target.value)}
                                                style={dropdownStyle}
                                            >
                                                <option value={contractType}>{contractType}</option>

                                                <option value='Permanent'>Permanent</option>
                                                <option value='Indefinite Duration'>Indefinite Duration</option>
                                                <option value='Specified Purpose'>Specified Purpose</option>
                                                <option value='Fixed Term'>Fixed Term</option>
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId='wte'>
                                            <Form.Label>Enter Whole Time Equ</Form.Label>
                                            <Form.Control
                                                type='number'
                                                placeholder='Whole Time Equ'
                                                value={wte}
                                                onChange={(e) => setWte(e.target.value)}
                                                style={textStyle}
                                                step='0.01' // Set the step attribute to 0.01
                                            >
                                               
                                               
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId='swpiLogNo'>
                                            <Form.Label>SWPI Log No  </Form.Label>

                                            <Form.Control
                                                type='text'
                                                placeholder='SWPI Log No  '
                                                value={swpiLogNo}
                                                onChange={(e) => setSwpiLogNo(e.target.value)} style={textStyle}
                                            ></Form.Control>

                                        </Form.Group>

                                        <Form.Group controlId='Comments'>
                                        <Form.Label>Comments</Form.Label>
                                        <Form.Control
                                            as='textarea'
                                            rows={11}
                                            placeholder='Enter your comments here...'
                                            value={comments}
                                            onChange={(e) => setComments(e.target.value)}
                                            style={textStyle}
                                        />
                                    </Form.Group>


                                       
                                    </div>
                                    </Col>
                                    



                                    {/* third ivumn **************************************************************************************/}
                                    <Col md={5}>

                                    

                                    <div style={{ border: '1px solid green', backgroundColor: 'rgb(202, 250, 241)', padding: '10px' }}>


                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ flex: '1' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <h2 style={{ marginRight: 'auto' }}>ECC Update</h2>
                                                            {/* Button on the far right */}
                                                            <Button
                                                                type='submit'
                                                                variant='primary'
                                                                style={{ marginTop: '1rem', marginLeft: '1rem' }}
                                                            >
                                                                Update
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>

                                            
                                            {/* show as row on wide screen and column on narrow screen  {containerStyle} defined at top*/}
                                            <div style={containerStyle}>

                                            <Form.Group controlId='EccNumber'>
                                            <Form.Label>Ecc Number  </Form.Label>

                                            <Form.Control
                                                type='text'
                                                placeholder='Ecc Number  '
                                                value={eccNumber}
                                                onChange={(e) => setEccNumber(e.target.value)} 
                                                
                                                style={textboxStyle}
                                                
                                            ></Form.Control>
                                            </Form.Group>

                                            <Form.Group controlId='StartDate'>
                                            <Form.Label>Date post was first occupied</Form.Label>

                                            <Form.Control
                                                type='date'
                                                placeholder='Date the post was first occupied  '
                                                value={startDate ? startDate.substring(0, 10) : ""}

                                                onChange={(e) => setStartDate(e.target.value)} 
                                                
                                                
                                                style={{textboxStyle}}
                                            ></Form.Control>
                                            </Form.Group>
                                            </div>




                                                {/* show as row on wide screen and column on narrow screen  {containerStyle} defined at top*/}
                                                <div style={containerStyle}>

                                                <Form.Group controlId='FirstName'>
                                                <Form.Label>First Name</Form.Label>

                                                <Form.Control

                                                    type='string'
                                                    placeholder='First Name'
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)} 
                                                    
                                                    style={textboxStyle}
                                                ></Form.Control>

                                                </Form.Group> 

                                                <Form.Group controlId='surName'>
                                                    <Form.Label>SurName </Form.Label>

                                                    <Form.Control
                                                    
                                                        type='string'
                                                        placeholder='SurName  '
                                                        value={surName}
                                                        onChange={(e) => setSurName(e.target.value)} 
                                                        style={textboxStyle}
                                                    ></Form.Control>

                                                </Form.Group> 


                                                </div>
                                            {/* show as row on wide screen and column on narrow screen  {containerStyle} defined at top*/}
                                            <div style={containerStyle}>                                        
                                           

                                            <Form.Group controlId='setDirectorate'>
                                            <Form.Label>Directorate</Form.Label>
                                            <Form.Control

                                                as='select'
                                                placeholder='Directorate'
                                                value={directorate}

                                                //onChange={(e) => setActivityName(e.target.value)} style={dropdownStyle} 
                                                onChange={(e) => setDirectorate(e.target.value)} 
                                                style={textboxStyle}
                                            >


                                                <option value={directorate}>{directorate}</option>

                                                {directoratesdata &&
                                                    directoratesdata
                                                        .slice() // Create a shallow copy of the array to avoid mutating the original array
                                                        .sort((a, b) => a.directorate.localeCompare(b.directorate)) // Sort by activityName
                                                        .map((directorateList) => (
                                                            <option key={directorateList._id} value={`${directorateList.directorate}`}>
                                                                {directorateList.directorate}
                                                            </option>
                                                        ))}




                                            </Form.Control>
                                        </Form.Group>


                                            

                                            <Form.Group controlId='CostCentre'>
                                            <Form.Label>Cost Centre</Form.Label>
                                            <Form.Control

                                                as='select'
                                                placeholder='CostCentre'
                                                value={ccname}

                                                //onChange={(e) => setActivityName(e.target.value)} style={dropdownStyle} 
                                                onChange={(e) => setCcname(e.target.value)} 

                                               // style={{ width: '200px', flexBasis: 'calc(90% - 5px)', marginBottom: '10px' }}
                                                style={textboxStyle}

                                            >


                                                <option value={ccname}>{ccname}</option>

                                                {costcentresdata &&
                                                    costcentresdata
                                                        .slice() // Create a shallow copy of the array to avoid mutating the original array
                                                        .sort((a, b) => a.ccname.localeCompare(b.ccname)) // Sort by activityName
                                                        .map((costcentreList) => (
                                                            <option key={costcentreList._id} value={`${costcentreList.ccname}`}>
                                                                {costcentreList.ccname}
                                                            </option>
                                                        ))}




                                            </Form.Control>
                                        </Form.Group>


                                            </div>

                                            {/* show as row on wide screen and column on narrow screen  {containerStyle} defined at top*/}
                                            <div style={containerStyle}>

                                            <Form.Group controlId='AreaService'>
                                            <Form.Label>AreaService </Form.Label>
                                            
                                                            <Form.Control
                                                            
                                                                type='text'
                                                                placeholder='AreaService  '
                                                                value={areaService}
                                                                onChange={(e) => setAreaService(e.target.value)} 
                                                                
                                                                style={textboxStyle}
                                                            ></Form.Control>
                                                            </Form.Group>   


                                                            <Form.Group controlId='LineStatus' style={{  paddingLeft: '10px' }}>
                                                            <Form.Label>Line Status</Form.Label>
                                                            <Form.Control
                                                                as='select'
                                                                placeholder='Line Status'
                                                                value={lineStatus}
                                                                onChange={(e) => setLineStatus(e.target.value)}
                                                                
                                                                
                                                                style={textboxStyle}
                                                            >
                                                                <option value={lineStatus}>{lineStatus}</option>
                                                                <option value='Open'>Open</option>
                                                                <option value='Waiting ECC Approval'>Waiting ECC Approval</option>
                                                                <option value='Waiting Group Approval'>Waiting Group Approval</option>
                                                                <option value='Approved Beaumont'>Approved Beaumont</option>
                                                                <option value='Approved Group'>Approved Group</option>
                                                                <option value='Complete'>Complete</option>

                                                                </Form.Control>
                                                            </Form.Group>
                                                            </div>

                                                        {/* show as row on wide screen and column on narrow screen  {containerStyle} defined at top*/}
                                                         <div style={containerStyle}>

                                                            <Form.Group controlId='stormPostNumber'>
                                                            <Form.Label>Storm Post Number</Form.Label>
                                            
                                                            <Form.Control
                                                            
                                                                type='string'
                                                                placeholder='Storm Post Number '
                                                                value={stormPostNumber}
                                                                onChange={(e) => setStormPostNumber(e.target.value)} 
                                                                
                                                                style={textboxStyle}
                                                            ></Form.Control>
                                                            
                                                            </Form.Group> 

                                                            <Form.Group controlId='sapPostNumber'>
                                                                <Form.Label>SAP Post Number </Form.Label>
                                                
                                                                <Form.Control
                                                                
                                                                    type='string'
                                                                    placeholder='SAP Post Number  '
                                                                    value={sapPostNumber}
                                                                    onChange={(e) => setSapPostNumber(e.target.value)} 
                                                                    style={textboxStyle}
                                                                ></Form.Control>
                                                            
                                                            </Form.Group> 


                                                            </div>
                                                            
                                                            {/* style={{ ...textStyle, paddingLeft: '10px', width: '200px' }} 
                                                        show as row on wide screen and column on narrow screen  {containerStyle} defined at top*/}
                                                         <div style={containerStyle}>

                                                            <Form.Group controlId='dateECCRaised'>
                                                            <Form.Label>Date ECC Raised</Form.Label>

                                                            <Form.Control

                                                                type='date'
                                                                placeholder='Date ECC Raised '
                                                                
                                                                value={dateECCRaised ? dateECCRaised.substring(0, 10) : ""}

                                                                onChange={(e) => setDateECCRaised(e.target.value)} 
                                                                style={textboxStyle}
                                                            ></Form.Control>

                                                            </Form.Group> 

                                                            <Form.Group controlId='dateECCApproved'>
                                                                                                                            <Form.Label>Date ECC Approved </Form.Label>
                                                                                                            
                                                                                                                            <Form.Control
                                                                                                                            
                                                                                                                                type='date'
                                                                                                                                placeholder='Date ECC Approved  '
                                                                                                                                
                                                                                                                                value={dateECCApproved ? dateECCApproved.substring(0, 10) : ""}
                                                                                                                                onChange={(e) => setDateECCApproved(e.target.value)} 
                                                                                                                                style={textboxStyle}                                                                     
                                                                                                                        
                                                                                                                            

                                                                                                                            ></Form.Control>
                                                                                                                        
                                                                                                                        </Form.Group> 


                                                                                                                    </div>





                                                                                                                    {/* width 200px, show as row on wide screen and column on narrow screen  {containerStyle} defined at top*/}
                                                                                                                    <div style={containerStyle}>


                                                                                                                        <Form.Group controlId='dateSentToGroup'>
                                                                                                                        <Form.Label>Date Sent To Group</Form.Label>
                                                                                                        
                                                                                                                        <Form.Control
                                                                                                                        
                                                                                                                            type='date'
                                                                                                                            placeholder='Date Sent To Group '
                                                                                                                            
                                                                                                                            value={dateSentToGroup ? dateSentToGroup.substring(0, 10) : ""}

                                                                                                                            onChange={(e) => setDateSentToGroup(e.target.value)}  
                                                                                                                            
                                                                                                                            style={textboxStyle} 


                                                                                                                        ></Form.Control>
                                                                                                                        
                                                                                                                        </Form.Group> 

                                                                                                                        <Form.Group controlId='dateApprovedByGroup'>
                                                                <Form.Label>Date Approved By Group</Form.Label>

                                                                <Form.Control
                                                                
                                                                    type='date'
                                                                    placeholder='Date Approved By Group  '
                                                                    
                                                                    value={dateApprovedByGroup ? dateApprovedByGroup.substring(0, 10) : ""}
                                                                    onChange={(e) => setDateApprovedByGroup(e.target.value)} 
                                                                    style={textboxStyle}
                                                                ></Form.Control>

                                                            </Form.Group> 


                                                            </div>

                                                           

                                                            

                                                         {/* show as row on wide screen and column on narrow screen  {containerStyle} defined at top*/}
                                                         <div style={containerStyle}>

                                                            <Form.Group controlId='Line Comment'>
                                                            <Form.Label>Comments</Form.Label>
                                                            <Form.Control
                                                                as='textarea'
                                                                rows={4}
                                                                placeholder='Enter your comments here...'
                                                                value={lineComment}
                                                                onChange={(e) => setLineComment(e.target.value)}
                                                                style={textboxStyle} 
                                                            />
                                                            </Form.Group>


                                                            <Form.Group controlId='dateRecived'>
                                                                    <Form.Label>Date Recived</Form.Label>

                                                                    <Form.Control
                                                                    
                                                                        type='date'
                                                                        placeholder='Date Recived  '
                                                                        
                                                                        value={dateRecived ? dateRecived.substring(0, 10) : ""}
                                                                        onChange={(e) => setDateRecived(e.target.value)} 
                                                                        style={textboxStyle}
                                                                        

                                                                    ></Form.Control>

                                                                </Form.Group> 
                                                        </div> 






                                                            



                                        </div>


                                    </Col>
                                </Row>

                                

                                <div style={{ display: 'flex' , paddingLeft: '10px'}}>
                                <Button
                                ref={updateButtonRef}
                                    type='submit'
                                    variant='primary'
                                    className='btn-sm'
                                    style={{ marginTop: '1rem', paddingRight: '10px' }}
                                >
                                <FaEdit />  Update
                                </Button>

                                
                        <div style={{  paddingLeft: '10px'}}> 
                                <Button
                                variant='warning'
                                className='btn-sm'
                                style={{  paddingLeft: '10px', marginTop: '1rem' }}
                            onClick={() => deleteHandler(primaryNotificationId )}
                            >
                                <FaTrash  style={{ color: 'white' }}  /> Delete
                            </Button>
                        </div>

                    </div>

                            </Form>
                        </div>
                    )}



                </Col>
            </Row>
        </>
    );
};

export default PrimaryNotificationEditItemScreen;
