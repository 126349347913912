import { COSTCENTRES_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const costcentresApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCostcentres: builder.query({
            query: ({ keyword, pageNumber }) => ({
                url: COSTCENTRES_URL,
                params: { keyword, pageNumber },
            }),
            keepUnusedDataFor: 5,
            providesTags: ['TheCostcentres'],
        }),

        getCostcentresNoPage: builder.query({
            query: () => ({
                url: `${COSTCENTRES_URL}/list`,

            }),
            keepUnusedDataFor: 5,
            providesTags: ['TheCostcentres'],
        }),




        getGradeDetails: builder.query({
            query: (costcentreId) => ({
                url: `${COSTCENTRES_URL}/${costcentreId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        createGrade: builder.mutation({
            query: () => ({
                url: `${COSTCENTRES_URL}`,
                method: 'POST',
            }),
            invalidatesTags: ['TheCostcentres'],
        }),
        updateGrade: builder.mutation({
            query: (data) => ({
                url: `${COSTCENTRES_URL}/${data.costcentreId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['TheCostcentres'],
        }),
        deleteGrade: builder.mutation({
            query: (costcentreId) => ({
                url: `${COSTCENTRES_URL}/${costcentreId}`,
                method: 'DELETE',
            }),
            providesTags: ['TheCostcentres'],
        }),


    }),
});

export const {
    useGetCostcentresQuery,
    useGetCostcentresNoPageQuery,
    useGetGradeDetailsQuery,
    useCreateGradeMutation,
    useUpdateGradeMutation,
    useDeleteGradeMutation,

} = costcentresApiSlice;
