import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import {
    useGetClinicalSiteDetailsQuery,
    useUpdateClinicalSiteMutation,

} from '../../slices/clinicalSitesApiSlice';

const ClinicalSiteEditScreen = () => {
    const { id: clinicalSiteId } = useParams();

    const [clinicalSiteName, setClinicalSiteName] = useState('');
    const [description, setDescription] = useState('');
    const [activeYorN, setActiveYorN] = useState('');
    const [paidYorN, setPaidYorN] = useState('');




    const {
        data: clinicalSite,
        isLoading,
        refetch,
        error,
    } = useGetClinicalSiteDetailsQuery(clinicalSiteId);

    //#f5f5dc
    const dropdownStyle = {
        backgroundColor: '#F5F5F5'                 //'lightyellow',
        // Add other styles as needed
    };
    const textStyle = {
        backgroundColor: '#f5f5dc'
        // Add other styles as needed
    };



    const [updateClinicalSite, { isLoading: loadingUpdate }] =
        useUpdateClinicalSiteMutation();


    const navigate = useNavigate();

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            await updateClinicalSite({
                clinicalSiteId,
                clinicalSiteName,
                description,
                activeYorN,
                paidYorN,

            }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
            toast.success('Clinical Site updated');
            refetch();
            navigate('/admin/clinicalSitelist');
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    useEffect(() => {
        if (clinicalSite) {
            setClinicalSiteName(clinicalSite.clinicalSiteName);
            setDescription(clinicalSite.description);
            setActiveYorN(clinicalSite.activeYorN);
            setPaidYorN(clinicalSite.paidYorN);

        }
    }, [clinicalSite]);


    return (
        <>
            <Link to='/admin/clinicalSitelist' className='btn btn-light my-3'>
                Go Back
            </Link>
            <FormContainer>
                <h1>Edit Clinical Site</h1>
                {loadingUpdate && <Loader />}
                {isLoading ? (
                    <Loader />
                ) : error ? (
                    <Message variant='danger'>{error.data.message}</Message>
                ) : (
                    <Form onSubmit={submitHandler}>


                        <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>

                            <Form.Group controlId='name'>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter Name'
                                    value={clinicalSiteName}
                                    onChange={(e) => setClinicalSiteName(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='description'>
                                <Form.Label>Description - Comments</Form.Label>

                                <Form.Control
                                    type='text'
                                    placeholder='Description - Comments'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>


                            <Form.Group controlId='activeYorN'>
                                <Form.Label>Active Yes or No</Form.Label>
                                <Form.Control
                                    as='select'
                                    placeholder='Active YorN'
                                    value={activeYorN}

                                    onChange={(e) => setActiveYorN(e.target.value)} style={dropdownStyle} >
                                    <option value={activeYorN}>{activeYorN}</option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='paidYorN'>
                                <Form.Label>Paid Yes or No</Form.Label>


                                <Form.Control
                                    as='select'
                                    placeholder='Paid YorN'
                                    value={paidYorN}
                                    onChange={(e) => setPaidYorN(e.target.value)} style={dropdownStyle} >
                                    <option value={paidYorN}>{paidYorN}</option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>


                                </Form.Control>


                            </Form.Group>

                        </div>



                        <Button
                            type='submit'
                            variant='primary'
                            style={{ marginTop: '1rem' }}
                        >
                            Update
                        </Button>
                    </Form>
                )}
            </FormContainer>
        </>
    );
};

export default ClinicalSiteEditScreen;
