import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { FaEdit, FaPlus, FaTrash, FaTimes } from 'react-icons/fa';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import Message from '../components/Message';
import Loader from '../components/Loader';
import TimePicker from '../components/TimePicker.jsx';

import {
    useGetMyWorklistsQuery, useCreateWorklistMutation, useGetWorklistDetailsQuery, useGetWorklistItemsQuery,
    useUpdateWorklistMutation, useUpdateWorklistActivityMutation,
} from '../slices/worklistsApiSlice';

import { useGetMyClinicalSCommitmentsQuery } from '../slices/clinicalSCommitmentsApiSlice';

import { setCredentials } from '../slices/authSlice';
import FormContainer from '../../src/components/FormContainer';

import {
    useGetActivitiesNoPageQuery,
    useDeleteActivityMutation,
    useCreateActivityMutation,
} from '../slices/activitiesApiSlice';


import {
    useGetClinicalSitesNPQuery,

} from '../slices/clinicalSitesApiSlice';


//

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import '../../src/components/TimePicker.css'







//#f5f5dc
const dropdownStyle = {
    backgroundColor: '#F5F5F5'    //'#F0E68C'                 //'lightyellow',
    // Add other styles as needed
};
const textStyle = {
    backgroundColor: '#f5f5dc'
    // Add other styles as needed
};


const WorklistEditItemScreen = () => {

    const { pageNumber } = useParams();
    const { data: myClinicalCommitmants, isLoading: isLoadingCC, error: errorCC, refetch: refetchCC } = useGetMyClinicalSCommitmentsQuery({
        pageNumber,
    });

    // State for start and end times
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());


    // Handle changes in start time
    const handleStartTimeChange = (date) => {
        setStartTime(date);

        // Get the hour and minutes from the date
        const hour = date.getHours();
        const minutes = date.getMinutes();
        setStartHour(date.getHours())
        setStartMin(date.getMinutes())

    };

    // Handle changes in end time
    const handleEndTimeChange = (date) => {
        setEndTime(date);

        // Get the hour and minutes from the date
        const hour = date.getHours();
        const minutes = date.getMinutes();
        setEndHour(date.getHours())
        setEndMin(date.getMinutes())

    };



    const { userInfo } = useSelector((state) => state.auth);

    // console.log('userInfo._id', userInfo._id);



    //const { data: worklists, isLoading, error } = useGetMyWorklistsQuery();

    //const { id: worklistId } = useParams();
    const { id: worklistId, UKey: UKey } = useParams();


    console.log('id: worklistId', worklistId);
    console.log(' UKey:', UKey);

    const [activityID, setActivityID] = useState('');
    const [activity, setActivity] = useState('');
    const [clinicalSiteID, setClinicalSiteID] = useState('');
    const [clinicalSite, setClinicalSite] = useState('');
    const [day, setDay] = useState('');
    const [period, setPeriod] = useState('');
    const [startHour, setStartHour] = useState(0);
    const [startMin, setStartMin] = useState(0);
    const [endHour, setEndHour] = useState(0);
    const [endMin, setEndMin] = useState(0);
    const [comment, setComment] = useState(0);

    console.log(' clinicalSite:', clinicalSite);
    const {
        data: theItem,
        refetch,
        isLoading,
        error,
    } = useGetWorklistItemsQuery({ worklistID: worklistId, activityID: UKey });

    const { data: activitydata, isLoading: isLoadingAC, error: errorAC, refetch: refetchAC } = useGetActivitiesNoPageQuery();
    const { data: clinicalSitedata, isLoading: isLoadingCS, error: errorCS, refetch: refetchCS } = useGetClinicalSitesNPQuery();
    //console.log('activitydata ', activitydata);

    const dispatch = useDispatch();


    const [createWorklist, { isLoading: loadingCreate }] =
        useUpdateWorklistActivityMutation(worklistId);

    const createWorklistHandler = async () => {
        if (window.confirm('Are you sure you want to create a new Worklist?')) {
            try {
                await createWorklist();
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    //_____________________________________________________________________

    const [updateWorklistItem, { isLoading: loadingUpdate }] =
        useUpdateWorklistActivityMutation();



    const navigate = useNavigate();

    const submitHandler = async (e) => {
        e.preventDefault();
        console.log('frontend activity', activity);

        const details = {
            activityID,
            activity,
            clinicalSiteID,
            clinicalSite,
            day,
            period,
            startHour,
            startMin,
            endHour,
            endMin,
            comment,
        };
        console.log('details', details);

        console.log('UKey ', UKey)
        try {
            await updateWorklistItem({
                worklistId,
                details,
                UKey,

            }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
            toast.success('Item updated');
            refetch();
            navigate(`/worklist/${worklistId}`);
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    useEffect(() => {
        if (theItem) {
            setActivityID(theItem.activityID);
            setActivity(theItem.activity);
            setClinicalSiteID(theItem.clinicalSiteID);
            setClinicalSite(theItem.clinicalSite);
            setDay(theItem.day);
            setPeriod(theItem.period);
            setStartHour(theItem.startHour);
            setStartMin(theItem.startMin);
            setEndHour(theItem.endHour);
            setEndMin(theItem.endMin);
            setComment(theItem.comment);

            // Assuming you have separate hour and minute fields as numbers
            const selectedHour = theItem.startHour; // Replace with your actual hour value
            const selectedMinute = theItem.startMin; // Replace with your actual minute value

            // Create a new Date object and set the hours and minutes
            const newDate = new Date();
            newDate.setHours(selectedHour);
            newDate.setMinutes(selectedMinute);

            // Now, you can use the newDate as your startTime
            setStartTime(newDate);

            const selectedEHour = theItem.endHour; // Replace with your actual hour value
            const selectedEMinute = theItem.endMin; // Replace with your actual minute value

            // Create a new Date object and set the hours and minutes
            const newDateE = new Date();
            newDateE.setHours(selectedEHour);
            newDateE.setMinutes(selectedEMinute);

            // Now, you can use the newDate as your startTime
            setEndTime(newDateE);


        }
    }, [theItem]);


    //____________________________________________________________________//{`${activityList._id}:${activityList.activityName}`}



    return (
        <>
            <Link to={`/worklist/${worklistId}`} className='btn btn-light my-3'>
                Go Back
            </Link>
            <FormContainer>
                <h1>Edit Activity Form</h1>
                {loadingUpdate && <Loader />}
                {isLoadingAC && <Loader />}

                {isLoading ? (
                    <Loader />
                ) : error ? (
                    <Message variant='danger'>{error.data.message}</Message>
                ) : (

                    <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId='activity'>
                                <Form.Label>Activity</Form.Label>
                                <Form.Control

                                    as='select'
                                    placeholder='Enter activity'
                                    value={activity}

                                    //onChange={(e) => setActivityName(e.target.value)} style={dropdownStyle} 
                                    onChange={(e) => {
                                        const selectedOption = e.target.value;
                                        console.log('selectedOption', selectedOption);
                                        const [newActivityID, newActivityName] = selectedOption.split(':');
                                        setActivityID(newActivityID);
                                        setActivity(newActivityName);
                                    }}
                                >


                                    <option value={activity}>{activity}</option>

                                    {activitydata &&
                                        activitydata
                                            .slice() // Create a shallow copy of the array to avoid mutating the original array
                                            .sort((a, b) => a.activityName.localeCompare(b.activityName)) // Sort by activityName
                                            .map((activityList) => (
                                                <option key={activityList._id} value={`${activityList._id}:${activityList.activityName}`}>
                                                    {activityList.activityName}
                                                </option>
                                            ))}




                                </Form.Control>
                            </Form.Group>



                            <Form.Group controlId='clinicalSite'>
                                <Form.Label>Clinical Site</Form.Label>
                                <Form.Control

                                    as='select'
                                    placeholder='Enter clinical site'
                                    value={clinicalSite}

                                    //onChange={(e) => setActivityName(e.target.value)} style={dropdownStyle} 
                                    onChange={(e) => {
                                        const selectedOption = e.target.value;
                                        console.log('selectedOption', selectedOption);
                                        const [newClinicalSiteID, newClinicalSiteName] = selectedOption.split(':');
                                        setClinicalSiteID(newClinicalSiteID);
                                        setClinicalSite(newClinicalSiteName);
                                    }}
                                >


                                    <option value={clinicalSite}>{clinicalSite}</option>

                                    {myClinicalCommitmants.clinicalSCommitments &&
                                        myClinicalCommitmants.clinicalSCommitments
                                            .slice() // Create a shallow copy of the array to avoid mutating the original array
                                            .sort((a, b) => a.clinicalSiteName.localeCompare(b.clinicalSiteName)) // Sort by activityName
                                            .map((clinicalSiteList) => (
                                                <option key={clinicalSiteList._id} value={`${clinicalSiteList._id}:${clinicalSiteList.clinicalSiteName}`}>
                                                    {clinicalSiteList.clinicalSiteName}
                                                </option>
                                            ))}






                                </Form.Control>
                            </Form.Group>



                            <Form.Group controlId='day'>
                                <Form.Label>Enter day</Form.Label>
                                <Form.Control
                                    as='select'
                                    placeholder='Enter day'
                                    value={day}

                                    onChange={(e) => setDay(e.target.value)} style={dropdownStyle} >
                                    <option value={day}>{day}</option>
                                    <option value='1.Monday'>Monday</option>

                                    <option value='2.Tuesday'>Tuesday</option>
                                    <option value='3.Wednesday'>Wednesday</option>
                                    <option value='4.Thursday'>Thursday</option>
                                    <option value='5.Friday'>Friday</option>
                                    <option value='6.Saturday'>Saturday</option>
                                    <option value='7.Sunday'>Sunday</option>


                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='period'>
                                <Form.Label>Enter Monthly Period</Form.Label>
                                <Form.Control
                                    as='select'
                                    placeholder='Enter period'
                                    value={period}

                                    onChange={(e) => setPeriod(e.target.value)} style={dropdownStyle} >
                                    <option value={period}>{period}</option>
                                    <option value='Each Week'>Each Week</option>

                                    <option value='Week 1'>First week of Mth</option>
                                    <option value='Week 2'>Second week of Mth</option>
                                    <option value='Week 3'>Third week of Mth</option>
                                    <option value='Week 4'>Forth week of Mth</option>
                                    <option value='Week 5'>Fifth week of Mth</option>




                                </Form.Control>
                                <Form.Group controlId='comments'>
                                    <Form.Label>Comments</Form.Label>

                                    <Form.Control
                                        type='text'
                                        placeholder='Comments'
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)} style={textStyle}
                                    ></Form.Control>
                                </Form.Group>

                            </Form.Group>


                            <Form.Group controlId='startTime'>
                                <Form.Label>Start Time</Form.Label>
                                <DatePicker
                                    selected={startTime}
                                    onChange={handleStartTimeChange}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    timeFormat="HH:mm"
                                    dateFormat="HH:mm"
                                    className="custom-date-picker" // Add a custom class for styling
                                />
                            </Form.Group>


                            <Form.Group controlId='endTime'>
                                <Form.Label>End Time</Form.Label>
                                <DatePicker
                                    selected={endTime}
                                    onChange={handleEndTimeChange}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    className="custom-date-picker"
                                />

                            </Form.Group>





                            {/*
                        <Form.Group controlId='startHour'>
                            <Form.Label>Start Hour</Form.Label>
                            <Form.Control
                                type='number'
                                placeholder='Start Hour'
                                value={startHour}
                                onChange={(e) => setStartHour(e.target.value)}
                            ></Form.Control>
                        </Form.Group>


                        <Form.Group controlId='startMin'>
                            <Form.Label>Start Minute</Form.Label>
                            <Form.Control
                                type='number'
                                placeholder='Start Minute'
                                value={startMin}
                                onChange={(e) => setStartMin(e.target.value)}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='endHour'>
                            <Form.Label>End Hour</Form.Label>
                            <Form.Control
                                type='number'
                                placeholder='End Hour'
                                value={endHour}
                                onChange={(e) => setEndHour(e.target.value)}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='endMin'>
                            <Form.Label>End Minute</Form.Label>
                            <Form.Control
                                type='number'
                                placeholder='End Minute'
                                value={endMin}
                                onChange={(e) => setEndMin(e.target.value)}
                            ></Form.Control>

                        </Form.Group>
                                */}



                            <Button
                                type='submit'
                                variant='primary'
                                style={{ marginTop: '1rem' }}
                            >
                                Update
                            </Button>
                        </Form>
                    </div>
                )}
            </FormContainer>
        </>
    );
};

export default WorklistEditItemScreen;
