import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
    useGetRadiationSafetyDetailsQuery,
    useUpdateRadiationSafetyMutation,

} from '../slices/radiationSafetyApiSlice';

import {
    useGetClinicalSitesNPQuery,

} from '../slices/clinicalSitesApiSlice';

import {

    useGetUsersQuery,
} from '../slices/usersApiSlice';

const RadiationSafetytEditScreen = () => {

    const { pageNumber } = useParams();

    const { data: clinicalsitesdata, isLoading: isLoadingCS, error: errorCS, refetch: refetchCS } = useGetClinicalSitesNPQuery();
    const { data: users, refetch: refetchu, isLoading: isLoadingu, error: erroru } = useGetUsersQuery();



    const { id: radiationSafetyId } = useParams();
    const { userInfo } = useSelector((state) => state.auth);


    const [clinicalSiteName, setClinicalSiteName] = useState('');
    const [comments, setComments] = useState('');
    const [userID, setUserID] = useState(userInfo._id);
    const [consultantName, setConsultantName] = useState(userInfo.name);


    const [category, setCategory] = useState('');
    const [wholeBody, setWholeBody] = useState('');
    const [skin, setSkin] = useState('');
    const [lensEyes, setLensEyes] = useState('');
    const [neutron, setNeutron] = useState('');
    const [committedEffectiveDose, setCommittedEffectiveDose] = useState('');

    const [radionuclide, setRadionuclide] = useState('');
    const [activityOfRadionuclide, setActivityOfRadionuclide] = useState('');
    const [totalEffectiveDose, setTotalEffectiveDose] = useState('');

    const [activeYorN, setActiveYorN] = useState('');
    const [postHours, setPostHours] = useState('0');
    const [practicePlanHours, setPracticePlanHours] = useState('0');

    const [reviewDate, setReviewDate] = useState(new Date());
    const [effectiveDate, setEffectiveDate] = useState(new Date());



    const {
        data: radiationSafety,
        isLoading,
        refetch,
        error,
    } = useGetRadiationSafetyDetailsQuery(radiationSafetyId);

    //#f5f5dc
    const dropdownStyle = {
        backgroundColor: '#F5F5F5' //#F0E68C'   // '#f0f8ff'                 //'lightyellow',
        // Add other styles as needed
    };
    const textStyle = {
        backgroundColor: '#f5f5dc'
        // Add other styles as needed
    };



    const [updateRadiationSafety, { isLoading: loadingUpdate }] =
        useUpdateRadiationSafetyMutation();


    const navigate = useNavigate();

    const submitHandler = async (e) => {


        e.preventDefault();
        try {
            await updateRadiationSafety({

                radiationSafetyId,
                clinicalSiteName,
                userID,
                consultantName,

                category,
                wholeBody,
                skin,
                lensEyes,
                neutron,
                committedEffectiveDose,
                radionuclide,
                activityOfRadionuclide,
                totalEffectiveDose,

                comments,
                activeYorN,

                reviewDate,
                effectiveDate,



            }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
            toast.success('Radiation Safety record updated');
            refetch();
            navigate('/radiationsafetylist');
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    /*
    useEffect(() => {
        // Set an initial date when the component mounts
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        setReviewDate(formattedDate);
        

    }, []); // The empty dependency array ensures this effect runs only once when the component mounts

*/

    useEffect(() => {


        if (radiationSafety) {




            const reviewDate1 = new Date((radiationSafety.reviewDate));
            //const reviewDate2 = reviewDate1.toISOString().slice(0, 10);
            //const reviewDate2 = reviewDate1 ? reviewDate1.toISOString().slice(0, 10) : '';
            //const reviewDate2 = (reviewDate1 ?? '').toISOString().slice(0, 10);

            let reviewDate2 = ""

            if (reviewDate1 && reviewDate1 instanceof Date && !isNaN(reviewDate1.getTime())) {
                reviewDate2 = reviewDate1.toISOString().slice(0, 10);
                // Further processing with reviewDate2
            } else {
                reviewDate2 = ""
                //console.error('reviewDate1 is not a valid Date object or is null/undefined');
                // Handle the case where reviewDate1 is not a valid date or is null/undefined
            }



            const effectiveDate1 = new Date((radiationSafety.effectiveDate));
            //const effectiveDate2 = effectiveDate1.toISOString().slice(0, 10);
            //const effectiveDate2 = (effectiveDate1 ?? '').toISOString().slice(0, 10);

            let effectiveDate2 = ""

            if (effectiveDate1 && effectiveDate1 instanceof Date && !isNaN(effectiveDate1.getTime())) {
                effectiveDate2 = effectiveDate1.toISOString().slice(0, 10);
                // Further processing with reviewDate2
            } else {
                effectiveDate2 = ""
                //console.error('reviewDate1 is not a valid Date object or is null/undefined');
                // Handle the case where reviewDate1 is not a valid date or is null/undefined
            }



            setClinicalSiteName(radiationSafety.clinicalSiteName);
            setComments(radiationSafety.comments);
            setActiveYorN(radiationSafety.activeYorN);
            setPostHours(radiationSafety.postHours);
            setPracticePlanHours(radiationSafety.practicePlanHours);
            setReviewDate(reviewDate2);
            setEffectiveDate(effectiveDate2);

            setCategory(radiationSafety.category);
            setWholeBody(radiationSafety.wholeBody);
            setSkin(radiationSafety.skin);
            setLensEyes(radiationSafety.lensEyes);
            setNeutron(radiationSafety.neutron);
            setCommittedEffectiveDose(radiationSafety.committedEffectiveDose);
            setRadionuclide(radiationSafety.radionuclide);
            setActivityOfRadionuclide(radiationSafety.activityOfRadionuclide);
            setTotalEffectiveDose(radiationSafety.totalEffectiveDose);

        }
    }, [radiationSafety]);


    return (
        <>
            <Link to='/radiationsafetylist' className='btn btn-light my-3'>
                Go Back
            </Link>
            <FormContainer>
                <h1>Edit radiation safety record</h1>
                {loadingUpdate && <Loader />}
                {isLoadingCS && <Loader />}

                {isLoading ? (
                    <Loader />
                ) : error ? (
                    <Message variant='danger'>{error.data.message}</Message>
                ) : (
                    <Form onSubmit={submitHandler}>


                        <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>
                            <Form.Group controlId='name'>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    as='select'
                                    placeholder='clinical Site Name'
                                    value={clinicalSiteName}

                                    onChange={(e) => setClinicalSiteName(e.target.value)} style={dropdownStyle} >


                                    <option value=''>Select a clinical site</option>
                                    {clinicalsitesdata && clinicalsitesdata.map && clinicalsitesdata.map((clinSite) => (
                                        <option key={clinSite.clinicalSiteName} value={clinSite.clinicalSiteName}>
                                            {clinSite.clinicalSiteName}
                                        </option>
                                    ))}

                                    { /*users.map((clinicalSite) => (
                                    <option key={clinicalSite.name} value={clinicalSite.name}>
                                        {clinicalSite.name}
                                    </option>
                                )) */}


                                </Form.Control>

                            </Form.Group>

                            <Form.Group controlId='category'>
                                <Form.Label>category A or B </Form.Label>
                                <Form.Control
                                    as='select'
                                    placeholder='category'
                                    value={category}

                                    onChange={(e) => setCategory(e.target.value)} style={dropdownStyle} >
                                    <option value={category}>{category}</option>
                                    <option value='Category A'>Category A</option>
                                    <option value='Category B'>Category B</option>
                                </Form.Control>
                            </Form.Group>


                            <Form.Group controlId='wholeBody'>
                                <Form.Label>whole Body</Form.Label>

                                <Form.Control
                                    type='text'
                                    placeholder='wholeBody'
                                    value={wholeBody}
                                    onChange={(e) => setWholeBody(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='skin'>
                                <Form.Label>skin</Form.Label>

                                <Form.Control
                                    type='text'
                                    placeholder='skin'
                                    value={skin}
                                    onChange={(e) => setSkin(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='lensEyes'>
                                <Form.Label>Lens Eyes</Form.Label>

                                <Form.Control
                                    type='text'
                                    placeholder='lensEyes'
                                    value={lensEyes}
                                    onChange={(e) => setLensEyes(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='neutrons'>
                                <Form.Label>Neutron</Form.Label>

                                <Form.Control
                                    type='text'
                                    placeholder='neutron'
                                    value={neutron}
                                    onChange={(e) => setNeutron(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='committedEffectiveDose'>
                                <Form.Label>Committed Effective Dose</Form.Label>

                                <Form.Control
                                    type='text'
                                    placeholder='committedEffectiveDose'
                                    value={committedEffectiveDose}
                                    onChange={(e) => setCommittedEffectiveDose(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='radionuclide'>
                                <Form.Label>Radionuclide</Form.Label>

                                <Form.Control
                                    type='text'
                                    placeholder='radionuclide'
                                    value={radionuclide}
                                    onChange={(e) => setRadionuclide(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='activityOfRadionuclide'>
                                <Form.Label>ActivityOfRadionuclide</Form.Label>

                                <Form.Control
                                    type='text'
                                    placeholder='activityOfRadionuclide'
                                    value={activityOfRadionuclide}
                                    onChange={(e) => setActivityOfRadionuclide(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='totalEffectiveDose'>
                                <Form.Label>Total Effective Dose mSv</Form.Label>

                                <Form.Control
                                    type='text'
                                    placeholder='totalEffectiveDose  mSv'
                                    value={totalEffectiveDose}
                                    onChange={(e) => setTotalEffectiveDose(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>



                            <Form.Group controlId='effectiveDate'>
                                <Form.Label>Effective Date  </Form.Label>
                                <Form.Control
                                    type='date'
                                    placeholder="Effective Date "
                                    value={effectiveDate}   // {effectiveDate.toISOString().split('T')[0]}  //{effectiveDate}
                                    onChange={(e) => setEffectiveDate(e.target.value)}
                                    style={textStyle}
                                />


                            </Form.Group>

                            <Form.Group controlId='reviewDate'>
                                <Form.Label>Review Date </Form.Label>

                                <Form.Control

                                    type='date'

                                    value={reviewDate}
                                    onChange={(e) => setReviewDate(e.target.value)}
                                    style={textStyle}
                                />

                            </Form.Group>



                            <Form.Group controlId='comments'>
                                <Form.Label>Comments</Form.Label>

                                <Form.Control
                                    type='text'
                                    placeholder='Comments'
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>




                            <Form.Group controlId='activeYorN'>
                                <Form.Label>Active Yes or No</Form.Label>
                                <Form.Control
                                    as='select'
                                    placeholder='Active YorN'
                                    value={activeYorN}

                                    onChange={(e) => setActiveYorN(e.target.value)} style={dropdownStyle} >
                                    <option value={activeYorN}>{activeYorN}</option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                            </Form.Group>

                        </div>





                        <Button
                            type='submit'
                            variant='primary'
                            style={{ marginTop: '1rem' }}
                        >
                            Update
                        </Button>
                    </Form>
                )}
            </FormContainer>
        </>
    );
};

export default RadiationSafetytEditScreen;
