import { GRADES_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const gradesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getGrades: builder.query({
            query: ({ keyword, pageNumber }) => ({
                url: GRADES_URL,
                params: { keyword, pageNumber },
            }),
            keepUnusedDataFor: 5,
            providesTags: ['TheGrades'],
        }),

        getGradesNoPage: builder.query({
            query: () => ({
                url: `${GRADES_URL}/list`,

            }),
            keepUnusedDataFor: 5,
            providesTags: ['TheGrades'],
        }),




        getGradeDetails: builder.query({
            query: (gradeId) => ({
                url: `${GRADES_URL}/${gradeId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        createGrade: builder.mutation({
            query: () => ({
                url: `${GRADES_URL}`,
                method: 'POST',
            }),
            invalidatesTags: ['TheGrades'],
        }),
        updateGrade: builder.mutation({
            query: (data) => ({
                url: `${GRADES_URL}/${data.gradeId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['TheGrades'],
        }),
        deleteGrade: builder.mutation({
            query: (gradeId) => ({
                url: `${GRADES_URL}/${gradeId}`,
                method: 'DELETE',
            }),
            providesTags: ['TheGrades'],
        }),


    }),
});

export const {
    useGetGradesQuery,
    useGetGradesNoPageQuery,
    useGetGradeDetailsQuery,
    useCreateGradeMutation,
    useUpdateGradeMutation,
    useDeleteGradeMutation,

} = gradesApiSlice;
