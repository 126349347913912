import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { FaEdit, FaPlus, FaTrash, FaTimes, } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import Message from '../components/Message.jsx';
import Loader from '../components/Loader.jsx';

import {
    useGetMyPrimaryNotificationsQuery, useCreatePrimaryNotificationMutation, useGetPrimaryNotificationDetailsQuery,
    useCreatePNActivityMutation
} from '../slices/primaryNotificationApiSlice.js';

import { setCredentials } from '../slices/authSlice.js';


import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import '../../src/components/TimePicker.css'
import MyClinicalSCommitments from '../components/MyClinicalCommitments.jsx';
import MyProfile from '../components/MyProfile.jsx';
import { calcTotal } from '../utils/calcTotals.js'



//#f5f5dc
const dropdownStyle = {
    backgroundColor: '#F5F5F5'    //'#F0E68C'                 //'lightyellow',
    // Add other styles as needed
};
const textStyle = {
    backgroundColor: '#f5f5dc'
    // Add other styles as needed
};




const PrimaryNotificationEditScreen = () => {



    const { userInfo } = useSelector((state) => state.auth);
    

    //console.log('PrimaryNotificationEditActivityScreen: userInfo._id', userInfo._id);

    //const { data: primaryNotifications, isLoading, error } = useGetMyPrimaryNotificationsQuery();

    const { id: primaryNotificationIdAndWTE } = useParams();

    const [primaryNotificationId, pnWteIn] = primaryNotificationIdAndWTE.split(':');

    const [pnWte, setPnWte] = useState(+(pnWteIn));

    //console.log('PrimaryNotificationEditActivityScreen: userInfo._id', primaryNotificationId);


    // get the activities from the primaryNotification
    const {
        data: primaryNotification,
        refetch,
        isLoading,
        error,
    } = useGetPrimaryNotificationDetailsQuery(primaryNotificationId);


    const dispatch = useDispatch();


    const [createPNActivity, { isLoading: loadingCreate }] =
        useCreatePNActivityMutation(primaryNotificationId);

    const createPNActivityHandler = async () => {
        if (window.confirm(`Are you sure you want to create a new Line Item? for ${primaryNotificationId}`)) {
            try {
                await createPNActivity(primaryNotificationId);
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };


    useEffect(() => {
        refetch();
      }, [primaryNotification]);



    let totalWTE = 0 //+calcTotal(primaryNotification.primaryNotificationItems);
    const roundedWTE = +(Math.round(pnWte * 100) / 100).toFixed(2);

    return (
        <>
            <Link to='/primaryNotification' className='btn btn-light mb-4'>
                Go Back
            </Link>


            <Row>
                <Col md={12}>

                    <Row>
                        <Col md={6}>
                            {primaryNotification ? (
                                <h3>Primary Notification {primaryNotification.primaryNotification} , <span style={{backgroundColor: '#ffffcc', display: 'inline-block', border: '1px solid black', // 1px solid black border
                                padding: '5px'}}>WTE {pnWte}</span></h3>
                            ) : (
                                <h3>Loading...</h3>
                            )}

                        </Col>
                    </Row>

                   





                    <Col md={12} className='text-end'>
                        <Button className='my-3' onClick={createPNActivityHandler}>
                            <FaPlus />  Primary Notification Line Item
                        </Button>
                    </Col>



                    {isLoading ? (
                        <Loader />
                    ) : error ? (
                        <Message variant='danger'>
                            {error?.data?.message || error.error}
                        </Message>
                    ) : (


                        <Table striped bordered hover responsive className='table-sm'>
                            <thead>
                                <tr>
                                    <th>Service Area</th>
                                    <th>Location</th>
                                    <th>Title</th>
                                    <th>Sub Title</th>
                                    <th>Grade Code</th>

                                    <th>Grade</th>
                                    <th>Contract Type</th>
                                    <th>WTE</th>
                                    <th>Status</th>


                                    
                                    { 
                                    
                                    +calcTotal(primaryNotification.primaryNotificationItems) <= roundedWTE
                                     ? (
                                                    
                                                    <th style={{ backgroundColor: 'lightyellow' }}>
                                        Line Item's WTE's =  {+calcTotal(primaryNotification.primaryNotificationItems)} 
                                    </th>
                                                ) : (
                                                    
                                                    <th style={{ backgroundColor: '#F4BFBF' }}>
                                        Line Item's WTE's =  {+calcTotal(primaryNotification.primaryNotificationItems) } 
                                    </th>
                                                )
                                                
                                                
                                    }


                                    
                                </tr>
                            </thead>
                            <tbody>



                                {primaryNotification.primaryNotificationItems
                                    .slice()
                                    .filter(item => item.activeYorN !== "No")
                                    .map((item) => (
                                        <tr key={item.UKey} className={(item.serviceArea.startsWith('z') || item.serviceArea.startsWith('z')) ? 'highlightRow' : ''}>
                                            <td>{item.serviceArea}</td>
                                            <td>{item.location}</td>
                                            <td>{item.titleofInitiative}</td>
                                            <td>{item.subInitiative}</td>
                                            <td>{item.gradeCode}</td>
                                            <td>{item.grade}</td>
                                            <td>{item.contractType}</td>
                                            <td>{item.wte}</td>
                                            <td>{item.lineStatus}</td>

                                            <td>

                                            {/*<div style={{ display: 'flex', flexDirection: 'row' }}> */}

                                            <td style={{ display: 'flex', gap: '10px' }}>
                                                    <LinkContainer to={`/primaryNotification/${primaryNotification._id}:${primaryNotification.primaryNotification}:${pnWte}/activity/${item.UKey}`}>
                                                        <Button variant='outline-info' className='btn-sm mx-2'>
                                                            <FaEdit /> Edit Item
                                                        </Button>
                                                    </LinkContainer>

                                                    

                                                    {/*
                                                    <Button variant='primary'>Primary</Button>
                                                    <Button variant='secondary'>Secondary</Button>
                                                    <Button variant='success'>Success</Button>
                                                    <Button variant='danger'>Danger</Button>
                                                    <Button variant='warning'>Warning</Button>
                                                    <Button variant='info'>Info</Button>
                                                    <Button variant='light'>Light</Button>
                                                    <Button variant='dark'>Dark</Button>
                                                    <Button variant='link'>Link</Button>
                                                    */}

                                                </td>
                                                

                                            </td>
                                        </tr>
                                    ))}

                            </tbody>
                        </Table>
                    )}
                </Col>
            </Row >
        </>
    );
};

export default PrimaryNotificationEditScreen;
