import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const PaginateCommitments = ({ pages, page, isAdmin = false, keyword = '' }) => {

    let ListURL = ''

    if (isAdmin) {
        ListURL = '/admin/clinicalSCommitmentlist';
    } else {
        ListURL = '/clinicalSCommitmentlist';
    }

    console.log('isAdmin ', isAdmin);
    console.log('ListURL ', ListURL);

    return (
        pages > 1 && (
            <Pagination>
                {[...Array(pages).keys()].map((x) => (
                    <LinkContainer
                        key={x + 1}
                        to={
                            !isAdmin
                                ? `${ListURL}/${x + 1}`
                                : `${ListURL}/${x + 1}`
                        }


                    >
                        <Pagination.Item active={x + 1 === page}>{x + 1}</Pagination.Item>
                    </LinkContainer>
                ))}
            </Pagination>
        )
    );
};

export default PaginateCommitments;