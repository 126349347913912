import { apiSlice } from './apiSlice';
import { WORKLIST_URL } from '../constants';

export const worklistApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createWorklist: builder.mutation({
            query: () => ({
                url: `${WORKLIST_URL}`,
                method: 'POST',

            }),
        }),
        getWorklistDetails: builder.query({
            query: (id) => ({
                url: `${WORKLIST_URL}/${id}`,
            }),
            keepUnusedDataFor: 5,
        }),

        createActivity: builder.mutation({
            query: (worklistId) => ({


                url: `${WORKLIST_URL}/worklist/${worklistId}/activity`,
                method: 'PUT',

            }),
        }),


        getMyWorklists: builder.query({
            query: () => ({
                url: `${WORKLIST_URL}/mine`,
            }),
            keepUnusedDataFor: 5,
        }),
        getWorklists: builder.query({
            query: () => ({
                url: WORKLIST_URL,
            }),
            keepUnusedDataFor: 5,
        }),

        manApproveWorklist: builder.mutation({
            query: ({ worklistId, details }) => ({
                url: `${WORKLIST_URL}/${worklistId}/pay`,
                method: 'PUT',
                body: details,
            }),
        }),

        adminApproveWorklist: builder.mutation({
            query: (worklistId) => ({
                url: `${WORKLIST_URL}/${worklistId}/deliver`,
                method: 'PUT',
            }),

        }),


        updateWorklistActivity: builder.mutation({
            query: ({ worklistId, details, UKey }) => ({
                url: `${WORKLIST_URL}/worklist/${worklistId}/activity/${UKey}`,
                method: 'PUT',
                body: details,
            }),

        }),

        updateWorklist: builder.mutation({
            query: ({ worklistId, details }) => ({
                url: `${WORKLIST_URL}/worklist/${worklistId}`,
                method: 'PUT',
                body: details,
            }),

        }),

        getWorklistItems: builder.query({
            query: ({ worklistID, activityID }) => (

                {


                    url: `${WORKLIST_URL}/worklist/${worklistID}/activity/${activityID}`,
                }),

            keepUnusedDataFor: 5,
        }),



    }),
});

export const {
    useCreateWorklistMutation,
    useCreateActivityMutation,
    useGetWorklistDetailsQuery,
    useGetMyWorklistsQuery,
    useGetWorklistsQuery,
    useManApproveWorklistMutation,
    useAdminApproveWorklistMutation,
    useUpdateWorklistActivityMutation,
    useUpdateWorklistMutation,
    useGetWorklistItemsQuery,

} = worklistApiSlice;
