import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
//import { useParams } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Paginate from '../components/PaginateCommitments';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


import {

    useGetMyClinicalSCommitmentsQuery,
    useDeleteClinicalSCommitmentMutation,
    useCreateClinicalSCommitmentMutation,
} from '../slices/clinicalSCommitmentsApiSlice';
import { toast } from 'react-toastify';

const ClinicalSCommitmentListScreen = () => {
    //const { pageNumber } = useParams();
    const { userInfo } = useSelector((state) => state.auth);
    const { myUserId } = userInfo._id

    console.log('userInfo._id', userInfo._id);



    const { data, isLoading, error, refetch } = useGetMyClinicalSCommitmentsQuery({ myUserId });

    const [deleteClinicalSCommitment, { isLoading: loadingDelete }] =
        useDeleteClinicalSCommitmentMutation();

    const deleteHandler = async (id) => {
        if (window.confirm(`Are you sure  ${id}`)) {
            try {
                await deleteClinicalSCommitment(id);
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    const [createClinicalSCommitment, { isLoading: loadingCreate }] =
        useCreateClinicalSCommitmentMutation();

    const createClinicalSCommitmentHandler = async () => {
        if (window.confirm('Are you sure you want to create a new Clinical Commitment?')) {
            try {
                await createClinicalSCommitment();
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    // className='table-sm'th {




    return (
        <>

            <Link to='/' className='btn btn-light mb-4'>
                Go Back
            </Link>

            <Row className='align-items-center'>
                <Col>
                    <h1>Clinical Commitments</h1>
                </Col>
                <Col className='text-end'>
                    <Button className='my-3' onClick={createClinicalSCommitmentHandler}>
                        <FaPlus /> Create Clinical Commitments
                    </Button>
                </Col>
            </Row>

            {loadingCreate && <Loader />}
            {loadingDelete && <Loader />}
            {isLoading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error.data.message}</Message>
            ) : (
                <>

                    <div className='table-responsive{-sm|-md|-lg|-xl|-xxl}'>
                        <Table striped bordered hover responsive className='table-sm' position='sticky' top='0'>
                            <thead>
                                <tr>

                                    <th>Clinical Site Name</th>

                                    <th>Consultant Name</th>
                                    <th>Post Hours</th>
                                    <th>Practice Plan Hours</th>
                                    <th>Effective Date</th>
                                    <th>Review Date</th>

                                    <th> Comments</th>
                                    <th>Active Y or N</th>

                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.clinicalSCommitments.map((clinicalSCommitment) => (
                                    // Adjust 'en-GB' based on your locale clinicalSCommitment.reviewDate

                                    <tr key={clinicalSCommitment._id}>

                                        <td>{clinicalSCommitment.clinicalSiteName}</td>


                                        <td>{clinicalSCommitment.consultantName}</td>
                                        <td>{clinicalSCommitment.postHours}</td>
                                        <td>{clinicalSCommitment.practicePlanHours}</td>
                                        <td>{isNaN(Date.parse(clinicalSCommitment.effectiveDate)) ? '' : new Date(clinicalSCommitment.effectiveDate).toLocaleDateString('en-GB')}</td>
                                        <td>{isNaN(Date.parse(clinicalSCommitment.reviewDate)) ? '' : new Date(clinicalSCommitment.reviewDate).toLocaleDateString('en-GB')}</td>


                                        <td>{clinicalSCommitment.comments}</td>
                                        <td>{clinicalSCommitment.activeYorN}</td>

                                        <td>
                                            <LinkContainer to={`/clinicalSCommitment/${clinicalSCommitment._id}/edit`}>
                                                <Button variant='outline-info' className='btn-sm mx-2'>
                                                    <FaEdit /> Edit Item
                                                </Button>
                                            </LinkContainer>

                                            <Button
                                                variant='warning'
                                                className='btn-sm'
                                                onClick={() => deleteHandler(clinicalSCommitment._id)}
                                            >
                                                <FaTrash className='text-end' style={{ color: 'white' }} /> Delete
                                            </Button>

                                        </td>





                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <Paginate pages={data.pages} page={data.page} isAdmin={false} />
                </>
            )}
        </>
    );
};

export default ClinicalSCommitmentListScreen;
