import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import {
    useGetActivityDetailsQuery,
    useUpdateActivityMutation,

} from '../../slices/activitiesApiSlice';

const ActivityEditScreen = () => {
    const { id: activityId } = useParams();

    const [activityName, setActivityName] = useState('');
    const [supportOrClinical, setSupportOrClinical] = useState('');
    const [activeYorN, setActiveYorN] = useState('');
    const [paidYorN, setPaidYorN] = useState('');


    const {
        data: activity,
        isLoading,
        refetch,
        error,
    } = useGetActivityDetailsQuery(activityId);

    //#f5f5dc
    const dropdownStyle = {
        backgroundColor: '#F5F5F5'                 //'lightyellow',
        // Add other styles as needed
    };
    const textStyle = {
        backgroundColor: '#f5f5dc'
        // Add other styles as needed
    };



    const [updateActivity, { isLoading: loadingUpdate }] =
        useUpdateActivityMutation();


    const navigate = useNavigate();

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            await updateActivity({
                activityId,
                activityName,
                supportOrClinical,
                activeYorN,
                paidYorN,

            }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
            toast.success('Activity updated');
            refetch();
            navigate('/admin/activitylist');
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    useEffect(() => {
        if (activity) {
            setActivityName(activity.activityName);
            setSupportOrClinical(activity.supportOrClinical);
            setActiveYorN(activity.activeYorN);
            setPaidYorN(activity.paidYorN);

        }
    }, [activity]);


    return (
        <>
            <Link to='/admin/activitylist' className='btn btn-light my-3'>
                Go Back
            </Link>
            <FormContainer>
                <h1>Edit Activity</h1>
                {loadingUpdate && <Loader />}
                {isLoading ? (
                    <Loader />
                ) : error ? (
                    <Message variant='danger'>{error.data.message}</Message>
                ) : (
                    <Form onSubmit={submitHandler}>

                        <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>
                            <Form.Group controlId='name'>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type='name'
                                    placeholder='Enter name'
                                    value={activityName}
                                    onChange={(e) => setActivityName(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='supportOrClinical'>
                                <Form.Label>Support Or Clinical</Form.Label>

                                <Form.Control
                                    as='select'
                                    value={supportOrClinical}
                                    onChange={(e) => setSupportOrClinical(e.target.value)} style={dropdownStyle} >

                                    <option value={supportOrClinical}>{supportOrClinical}</option>
                                    <option value='Clinical'>Clinical</option>
                                    <option value='Support'>Support</option>
                                    <option value='Other'>Other</option>
                                </Form.Control>
                            </Form.Group>


                            <Form.Group controlId='activeYorN'>
                                <Form.Label>Active Yes or No</Form.Label>
                                <Form.Control
                                    as='select'
                                    placeholder='Active YorN'
                                    value={activeYorN}

                                    onChange={(e) => setActiveYorN(e.target.value)} style={dropdownStyle} >
                                    <option value={activeYorN}>{activeYorN}</option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='paidYorN'>
                                <Form.Label>Paid Yes or No</Form.Label>


                                <Form.Control
                                    as='select'
                                    placeholder='Paid YorN'
                                    value={paidYorN}
                                    onChange={(e) => setPaidYorN(e.target.value)} style={dropdownStyle} >
                                    <option value={paidYorN}>{paidYorN}</option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>


                                </Form.Control>


                            </Form.Group>
                        </div>



                        <Button
                            type='submit'
                            variant='primary'
                            style={{ marginTop: '1rem' }}
                        >
                            Update
                        </Button>
                    </Form>
                )}
            </FormContainer>
        </>
    );
};

export default ActivityEditScreen;
