import { Navbar, Nav, Container, NavDropdown, Badge } from 'react-bootstrap';
import { FaShoppingCart, FaUser } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { logout } from '../slices/authSlice';
import SearchBox from './SearchBox';
import logo from '../assets/BeaCrest.jpg';
import { resetCart } from '../slices/cartSlice';
import styled from 'styled-components';

  // Styled component for hiding the carousel on small screens
const HiddenCarousel = styled.div`
@media screen and (max-width: 767px) {
  display: none;
}
`;


const Header = () => {

  const { cartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      // NOTE: here we need to reset cart state for when a user logs out so the next
      // user doesn't inherit the previous users cart and shipping
      dispatch(resetCart());
      navigate('/login');
    } catch (err) {
      console.error(err);
    }
  };


  //<Navbar bg='primary' variant='dark' expand='lg' collapseOnSelect>

  return (
    <header>
      <Navbar style={{ backgroundColor: '#b1e1e7', height: '80px' }} variant='light' expand='lg' collapseOnSelect>

        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <img src={logo} alt='Beaumont Hospital' style={{ maxHeight: '60px', maxWidth: '100%', height: 'auto' }} />

              <Navbar.Text style={{ fontSize: '30px', marginLeft: '30px' }}>Beaumont Hospital</Navbar.Text>

            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ms-auto'>             
           
            {/*hide SearchBox on small screens */}
            <HiddenCarousel>
              <SearchBox style={{ display: 'none', '@media screen and (max-width: 767px)': { display: 'none' } }} />
            </HiddenCarousel>          


              { /*
              
              <LinkContainer to='/cart'>
                <Nav.Link>
                  <FaShoppingCart /> Cart
                  {cartItems.length > 0 && (
                    <Badge pill bg='success' style={{ marginLeft: '5px' }}>
                      {cartItems.reduce((a, c) => a + c.qty, 0)}
                    </Badge>
                  )}
                </Nav.Link>
              </LinkContainer>
                  */}

              {userInfo ? (
                <>
                  <NavDropdown title={userInfo.name} id='username' style={{ zIndex: '1000', backgroundColor: 'lightyellow'}}   > 
                    <LinkContainer to='/profile'>
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>                 



                    <NavDropdown.Item onClick={logoutHandler}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link>
                    <FaUser /> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}

              {/* Admin Links */}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title='Admin' id='adminmenu' style={{ zIndex: '1000' }} >
                 
                  {/*
                  <LinkContainer to='/admin/productlist'>
                    <NavDropdown.Item>Menu Items</NavDropdown.Item>
                  </LinkContainer>

                  
                  <LinkContainer to='/admin/orderlist'>
                    <NavDropdown.Item>Orders</NavDropdown.Item>
                  </LinkContainer>
              */}
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>

                 
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
